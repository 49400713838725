import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { GlobalStyles } from "@mui/system";
import styles from "./calendarComponent.module.css";

const CalendarComponent = ({ onDateSelect }) => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const handleDateClick = (date) => {
    onDateSelect(date); // Pass selected date back to parent
  };

  const clearDates = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  return (
    <div className={styles.calendarComponentContainer}>
      <GlobalStyles
        styles={{
          ".MuiDateRangeCalendar-root": {
            border: "none",
          },
        }}
      />
      <h1>Select Dates</h1>
      <p>Add your travel dates for exact pricing</p>
      <div className={styles.calendarContainer}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateRangeCalendar"]}>
            <DateRangeCalendar
              value={[selectedStartDate, selectedEndDate]}
              onChange={(newValue) => {
                setSelectedStartDate(newValue[0]);
                setSelectedEndDate(newValue[1]);
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
      <div>
        <button className={styles.clearButton} onClick={clearDates}>
          Clear dates
        </button>
      </div>
      <button onClick={() => handleDateClick("January 1, 2025")}>
        Select Jan 1, 2025
      </button>
    </div>
  );
};

export default CalendarComponent;
