import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CalendarIcon, GoBackIcon } from "../../../../components/svgs/rentpal";
import RentalImg from "../../../../assets/images/rental_img.png";
import styles from "./SecureBooking.module.css";
import { Height } from "@mui/icons-material";

const SecureBooking = () => {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  console.log(propertyId);

  const handleGoBack = () => {
    navigate(`/viewProperty/${propertyId}`);
  };

  const SecBkngBreakline = {
    width: "533px",
    height: "1px",
    background: "rgba(171, 171, 171, 0.50)",
    margin: "2vh 0",
  };

  return (
    <div className={styles.secureBookingContainer}>
      <div className={styles.secureBookingLeftContainer}>
        <button onClick={handleGoBack} className={styles.secureBookingBackBtn}>
          <GoBackIcon />
        </button>
        <div>
          <div className={styles.secureBookingHeader}>
            <h1>Secure Booking</h1>
          </div>
          <div className={styles.rentalDetailsContainer}>
            <h3>Rental Details</h3>
            <div className={styles.rentalDetailsContent}>
              <div className={styles.rentalDetails}>
                <h4>Date</h4>
                <p>Jan 1 , 2025</p>
              </div>
              <a>Edit</a>
            </div>
            <div className={styles.rentalDetailsContent}>
              <div className={styles.rentalDetails}>
                <h4>Space</h4>
                <p>Whole unit</p>
              </div>
              <a>Edit</a>
            </div>
          </div>
          <div style={SecBkngBreakline}></div>
          <div className={styles.secureBookingNoteContainer}>
            <label for="note">Add a note</label>
            <p>Optional</p>
            <textarea
              id="note"
              rows="3"
              placeholder="What are the legal requirements?"
            ></textarea>
          </div>
          <div style={SecBkngBreakline}></div>
          <div className={styles.secureBookingTiming}>
            <CalendarIcon />
            <p>
              Your reservation won’t be confirmed until the Host accepts your
              request (within 24 hours).
            </p>
          </div>
          <div style={SecBkngBreakline}></div>
          <div className={styles.secureBookingButtonsContainer}>
            <button className={styles.secureBookingCancelBtn}>Cancel</button>
            <button className={styles.secureBookingBookBtn}>
              Request to book
            </button>
          </div>
        </div>
      </div>
      <div className={styles.secureBookingRightContainer}>
        <div className={styles.rentalCardContainer}>
          <div className={styles.rentalCardHeader}>
            <img src={RentalImg} alt="Room" className={styles.rentalCardImg} />
            <div className={styles.rentalCardContentRating}>
              <h3>Elegant First-Floor Room & Kitchenette Access</h3>
              <p>
                <span>★</span>
                <span>4.5</span>
                <span>( 500 reviews )</span>
              </p>
            </div>
          </div>
          <div className={styles.rentalCardContentBody}>
            <div className={styles.totalRentalSections}>
              <p>Your total</p>
              <div className={styles.totalBreakDownSections}>
                <p>1 month</p>
                <p>Rs 10,000 /-</p>
              </div>
            </div>
            <div className={styles.totalAmountSections}>
              <p>Total (PKR)</p>
              <span>Rs 10,000 /-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecureBooking;
