import React, { useState, useEffect } from "react";
import styles from "./Payments.module.css";
import { useAuth } from "../../../../context/AuthContext";
import UserRentalListings from "../../../../components/userRentalListings/UserRentalListings";

const Payments = () => {
  const { authData } = useAuth(); // ✅ Get authData from useAuth hook
  const rentalInfo = authData?.data?.rentalInfo || []; // ✅ Extract rental info

  const [payments, setPayments] = useState({
    rent: [],
    utility: [],
    misc: [],
  });

  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedPayments, setSelectedPayments] = useState([]);

  const currentMonth = new Date().toLocaleString("default", { month: "long" });
  const currentYear = new Date().getFullYear();
  const currentMonthYear = `${currentMonth} ${currentYear}`;

  useEffect(() => {
    const dummyPayments = {
      rent: [
        { id: 1, amount: 1000, dueDate: "2024-12-01", paid: false },
        { id: 2, amount: 950, dueDate: "2024-11-15", paid: true },
      ],
      utility: [{ id: 3, amount: 200, dueDate: "2024-12-05", paid: false }],
      misc: [{ id: 4, amount: 50, dueDate: "2024-12-10", paid: false }],
    };
    setPayments(dummyPayments);
  }, []);

  const handlePaymentMethodChange = (e) => setPaymentMethod(e.target.value);

  const handlePaymentSelect = (e, paymentId) => {
    if (e.target.checked) {
      setSelectedPayments([...selectedPayments, paymentId]);
    } else {
      setSelectedPayments(selectedPayments.filter((id) => id !== paymentId));
    }
  };

  return (
    <div className={styles.paymentsContainer}>
      <h1 className={styles.pageTitle}>Your Pending Payments</h1>

      {/* Payment Method Section */}
      <div className={styles.paymentMethodSection}>
        <h2>Add Payment Method</h2>
        <label htmlFor="paymentMethod" className={styles.formLabel}>
          Payment Method:
        </label>
        <input
          type="text"
          id="paymentMethod"
          value={paymentMethod}
          onChange={handlePaymentMethodChange}
          placeholder="Enter payment method"
          className={styles.inputField}
        />
      </div>

      {/* Pending Payments Table */}
      <div className={styles.pendingPaymentsSection}>
        <h2>{currentMonthYear} Pending Payments</h2>
        <table className={styles.paymentTable}>
          <thead>
            <tr>
              <th>Select</th>
              <th>Payment Type</th>
              <th>Amount</th>
              <th>Due Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {[...payments.rent, ...payments.utility, ...payments.misc]
              .filter((payment) => !payment.paid)
              .map((payment) => (
                <tr key={payment.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(e) => handlePaymentSelect(e, payment.id)}
                    />
                  </td>
                  <td>
                    {payment.id <= 2
                      ? "Rent"
                      : payment.id === 3
                      ? "Utility"
                      : "Miscellaneous"}
                  </td>
                  <td>${payment.amount}</td>
                  <td>{new Date(payment.dueDate).toLocaleDateString()}</td>
                  <td>
                    <span className={styles.pendingStatus}>Pending</span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Rental Properties Section */}
      <div className={styles.rentalPropertiesSection}>
        <h2>Your Rental Properties</h2>
        {rentalInfo.length === 0 ? (
          <p className={styles.noRental}>You are not a tenant.</p>
        ) : (
          <ul className={styles.rentalList}>
            {rentalInfo.map((rental) => (
              <li key={rental._id} className={styles.rentalItem}>
                <strong>Property ID:</strong> {rental.propertyId} <br />
                <strong>Floor ID:</strong> {rental.floorId} <br />
                <strong>Room ID:</strong> {rental.roomId} <br />
                <strong>Rent Start Date:</strong>{" "}
                {new Date(rental.rentStartDate).toLocaleDateString()} <br />
                <strong>Status:</strong>{" "}
                {rental.rentEndDate ? "Inactive" : "Active"}
              </li>
            ))}
          </ul>
        )}
      </div>

      <UserRentalListings rentalInfo={rentalInfo} />
    </div>
  );
};

export default Payments;
