import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { URL } from "../../route";
import Cards from "../../components/cards/Cards";
import { useNavigate } from "react-router-dom";
import styles from "./viewAllProperties.module.css";

function ViewAllProperties() {
  const { authData } = useAuth();
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // console.log(authData);

  const fetchData = async () => {
    if (!authData || !authData.data || !authData.data._id) {
      console.error("authData is not available");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `${URL}/api/v1/auth/admin/viewAllProperties`,
        {
          params: { user: authData.data._id },
        }
      );

      if (response.data && response.data.properties) {
        setProperties(response.data.properties);
        localStorage.setItem(
          "properties",
          JSON.stringify(response.data.properties)
        );
        console.log("Successfully received Properties");
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedProperties = localStorage.getItem("properties");
    if (storedProperties) {
      setProperties(JSON.parse(storedProperties));
      setLoading(false);
    } else if (authData && authData.data && authData.data._id) {
      fetchData();
    } else {
      console.warn("authData is not available, skipping fetchData call.");
      setLoading(false); // Set loading to false to stop the skeleton from showing indefinitely
    }
  }, [authData]);

  // This hook is only for console logging properties data
  // useEffect(() => {
  //   if (
  //     properties &&
  //     properties.length > 0 &&
  //     properties[0].pictures &&
  //     properties[0].pictures.length > 0
  //   ) {
  //     console.log(properties);
  //   }
  // }, [properties]);

  const handleClick = (property) => {
    console.log("Property clicked", property);

    // Use a unique key for each property in sessionStorage
    const storageKey = `propertyData_${property._id}`;
    sessionStorage.setItem(storageKey, JSON.stringify(property));

    // Navigate to the view property page
    navigate(`/viewProperty/${property._id}`, {
      state: { propertyData: property }, // Pass property data through state
    });
  };

  return (
    <div className={styles.propertiesCardsContainer}>
      <h1>Available Properties</h1>
      <div className={styles.cards}>
        <Cards cardsData={properties} loading={loading} onClick={handleClick} />
      </div>
      <h3>Continue exploring rooms</h3>
      <div className={styles.propertiesCardsButtonContainer}>
        <button className={styles.propertiesCardsButton}>Show more</button>
      </div>
    </div>
  );
}

export default ViewAllProperties;
