import React, { useState } from "react";
import styles from "./NeedHelp.module.css";

const NeedHelp = () => {
  const [isHelpVisible, setIsHelpVisible] = useState(false);

  const toggleHelp = () => {
    setIsHelpVisible(!isHelpVisible);
  };

  return (
    <div className={styles.needHelpContainer}>
      <div className="help-page-container">
        <h1>Need Help? We're here for you!</h1>

        <section className="faq">
          <h2>Frequently Asked Questions</h2>
          <div className="faq-item">
            <h3>How do I reset my password?</h3>
            <p>
              To reset your password, click on 'Forgot Password' on the login
              page and follow the instructions.
            </p>
          </div>
          <div className="faq-item">
            <h3>How can I contact customer support?</h3>
            <p>
              You can contact our support team by email at support@example.com
              or call us at (123) 456-7890.
            </p>
          </div>
          {/* Add more FAQ items as needed */}
        </section>

        <section className="troubleshooting">
          <h2>Troubleshooting</h2>
          <p>
            If you're experiencing issues, please check our{" "}
            <a href="/troubleshooting">Troubleshooting Guide</a> or reach out to
            our support team.
          </p>
        </section>

        <section className="contact">
          <h2>Contact Us</h2>
          <p>
            If you're still having trouble, we're here to help! You can reach us
            through the following methods:
          </p>
          <button className={styles.helpButton} onClick={toggleHelp}>
            Need Help?
          </button>
          {isHelpVisible && (
            <div className={styles.helpContent}>
              <p>If you need assistance, please contact our support team at:</p>
              <p>
                <strong>Email:</strong>
                <a href="mailto:support@example.com">support@example.com</a>
              </p>
              <p>
                <strong>Phone:</strong> +1 (123) 456-7890
              </p>
              <p>
                Live Chat: <a href="/live-chat">Start a live chat</a>
              </p>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default NeedHelp;
