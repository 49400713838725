import React from "react";
import styles from "./ResdientsStats.module.css";
import { getChartStrokeDasharray } from "./ChartUtils";

const ResidentsStats = () => {
  return (
    <div className={styles.residentsStatsContainer}>
      <h2>Residential stats</h2>
      <div className={styles.residentsStatsContentContainer}>
        <div className={styles.residentsStatsContent}>
          <div className={styles.statsInfo}>
            <div className={styles.statItem}>
              <span className={`${styles.dot} ${styles.blackDot}`}></span>
              <div>
                <p>Total rentable units</p>
                <h3 className={styles.statValue}>5</h3>
              </div>
            </div>
            <div className={styles.statItem}>
              <span className={`${styles.dot} ${styles.grayDot}`}></span>
              <div>
                <p>Vacant residence slots</p>
                <h3 className={styles.statValue}>1</h3>
              </div>
            </div>
          </div>
          <div className={styles.statsChart}>
            <div className={styles.donutChart}>
              <svg viewBox="0 0 32 32">
                <circle
                  cx="16"
                  cy="16"
                  r="14"
                  fill="none"
                  stroke="#ddd"
                  strokeWidth="4"
                />
                <circle
                  cx="16"
                  cy="16"
                  r="14"
                  fill="none"
                  stroke="#000"
                  strokeWidth="4"
                  strokeDasharray={getChartStrokeDasharray(75, 25)}
                  transform="rotate(-90 16 16)"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.otherStatus}>
          <a href="#">Residents with other status (5)</a>
          <span style={{ cursor: "pointer" }}>&#10230;</span>
        </div>
      </div>
    </div>
  );
};

export default ResidentsStats;
