const propertyFormData = [
  //residential -> detatched house
  {
    category: "Residential",
    subcategory: "Detached House",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Building", "By Floors", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Property Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "rentPrice", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Marla", "Kanal", "Sq. meters"],
          },
          { label: "Number of Bedrooms", type: "number" },
          { label: "Number of Bathrooms", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Building Division (Conditional Step)",
        condition: { fieldKey: "rentingType", value: "By Floors" },
        repeatable: true,
        fields: [{ label: "Number of Rooms on this Floor", type: "number" }],
        subunits: {
          title: "Room Information",
          repeatable: true,
          fields: [
            { label: "Room Number", type: "text" },
            { label: "Room Size", type: "number" },
            { label: "Accomodation capacity", type: "number" },
            { label: "Room Rent Price", type: "number" },
          ],
        },
      },
      {
        title: "Building Division (By Rooms)",
        condition: { fieldKey: "rentingType", value: "By Rooms" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Room Size", type: "number" },
          { label: "Room Rent Price", type: "number" },
        ],
      },
      {
        title: "Building Division (By Seats)",
        condition: { fieldKey: "rentingType", value: "By Seats" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Seat Number", type: "text" },
          { label: "Seat Rent Price", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Garage/Parking", type: "checkbox" },
          { label: "Garden", type: "checkbox" },
          { label: "Swimming Pool", type: "checkbox" },
          { label: "Fireplace", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Furnished/Unfurnished", type: "checkbox" },
          { label: "Pet-Friendly", type: "checkbox" },
          { label: "Smoking Allowed", type: "checkbox" },
          { label: "Nearby Schools", type: "checkbox" },
          { label: "Nearby Public Transport", type: "checkbox" },
        ],
      },
    ],
  },
  //residential -> flat/appartment
  {
    category: "Residential",
    subcategory: "Flat/Apartment",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Building", "By Floors", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Property Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Marla", "Kanal", "Sq. meters"],
          },
          { label: "Floor Number", type: "number" },
          { label: "Number of Bedrooms", type: "number" },
          { label: "Number of Bathrooms", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Building Division (Conditional Step)",
        condition: { fieldKey: "rentingType", value: "By Floors" },
        repeatable: true,
        fields: [{ label: "Number of Rooms on this Floor", type: "number" }],
        subunits: {
          title: "Room Information",
          repeatable: true,
          fields: [
            { label: "Room Number", type: "text" },
            { label: "Room Size", type: "number" },
            { label: "Accomodation capacity", type: "number" },
            { label: "Room Rent Price", type: "number" },
          ],
        },
      },
      {
        title: "Building Division (By Rooms)",
        condition: { fieldKey: "rentingType", value: "By Rooms" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Room Size", type: "number" },
          { label: "Room Rent Price", type: "number" },
        ],
      },
      {
        title: "Building Division (By Seats)",
        condition: { fieldKey: "rentingType", value: "By Seats" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Seat Number", type: "text" },
          { label: "Seat Rent Price", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Elevator", type: "checkbox" },
          { label: "Balcony", type: "checkbox" },
          { label: "Parking", type: "checkbox" },
          { label: "Gym", type: "checkbox" },
          { label: "Swimming Pool", type: "checkbox" },
          { label: "Security/Doorman", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Furnished/Unfurnished", type: "checkbox" },
          { label: "Pet-Friendly", type: "checkbox" },
          { label: "Smoking Allowed", type: "checkbox" },
          { label: "Nearby Schools", type: "checkbox" },
          { label: "Nearby Public Transport", type: "checkbox" },
        ],
      },
    ],
  },
  //residential -> guest house
  {
    category: "Residential",
    subcategory: "Guest House",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Building", "By Floors", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Property Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Marla", "Kanal", "Sq. meters"],
          },
          { label: "Number of Rooms", type: "number" },
          { label: "Number of Bathrooms", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Building Division (Conditional Step)",
        condition: { fieldKey: "rentingType", value: "By Floors" },
        repeatable: true,
        fields: [{ label: "Number of Rooms on this Floor", type: "number" }],
        subunits: {
          title: "Room Information",
          repeatable: true,
          fields: [
            { label: "Room Number", type: "text" },
            { label: "Room Size", type: "number" },
            { label: "Accomodation capacity", type: "number" },
            { label: "Room Rent Price", type: "number" },
          ],
        },
      },
      {
        title: "Building Division (By Rooms)",
        condition: { fieldKey: "rentingType", value: "By Rooms" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Room Size", type: "number" },
          { label: "Room Rent Price", type: "number" },
        ],
      },
      {
        title: "Building Division (By Seats)",
        condition: { fieldKey: "rentingType", value: "By Seats" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Seat Number", type: "text" },
          { label: "Seat Rent Price", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Parking", type: "checkbox" },
          { label: "Garden", type: "checkbox" },
          { label: "Swimming Pool", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Furnished/Unfurnished", type: "checkbox" },
          { label: "Pet-Friendly", type: "checkbox" },
          { label: "Smoking Allowed", type: "checkbox" },
          { label: "Nearby Attractions", type: "checkbox" },
        ],
      },
    ],
  },
  //residential -> hostel
  {
    category: "Residential",
    subcategory: "Hostel",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Building", "By Floors", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Hostel Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent Price (Per Room/Seat)", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Marla", "Kanal", "Sq. meters"],
          },
          { label: "Number of Floors", type: "number" },
          { label: "Number of Rooms", type: "number" },
          { label: "Number of Bathrooms", type: "number" },
          {
            label: "Room Type",
            type: "select",
            options: ["Single", "Double", "Shared"],
          },
        ],
      },
      {
        title: "Building Division (Conditional Step)",
        condition: { fieldKey: "rentingType", value: "By Floors" },
        repeatable: true,
        fields: [{ label: "Number of Rooms on this Floor", type: "number" }],
        subunits: {
          title: "Room Information",
          repeatable: true,
          fields: [
            { label: "Room Number", type: "text" },
            { label: "Room Size", type: "number" },
            { label: "Accomodation capacity", type: "number" },
            { label: "Room Rent Price", type: "number" },
          ],
        },
      },
      {
        title: "Building Division (By Rooms)",
        condition: { fieldKey: "rentingType", value: "By Rooms" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Room Size", type: "number" },
          { label: "Room Rent Price", type: "number" },
        ],
      },
      {
        title: "Building Division (By Seats)",
        condition: { fieldKey: "rentingType", value: "By Seats" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Seat Number", type: "text" },
          { label: "Seat Rent Price", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Cafeteria", type: "checkbox" },
          { label: "Common Room", type: "checkbox" },
          { label: "Parking", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Furnished/Unfurnished", type: "checkbox" },
          { label: "Pet-Friendly", type: "checkbox" },
          { label: "Smoking Allowed", type: "checkbox" },
          { label: "Nearby Schools", type: "checkbox" },
          { label: "Nearby Public Transport", type: "checkbox" },
        ],
      },
    ],
  },
  //commercial -> shop
  {
    category: "Commercial",
    subcategory: "Shop",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Building", "By Floors", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Shop Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Sq. feet", "Sq. meters"],
          },
          { label: "Floor Number", type: "number" },
          { label: "Number of Bathrooms", type: "number" },
        ],
      },
      {
        title: "Building Division (Conditional Step)",
        condition: { fieldKey: "rentingType", value: "By Floors" },
        repeatable: true,
        fields: [{ label: "Number of Rooms on this Floor", type: "number" }],
        subunits: {
          title: "Room Information",
          repeatable: true,
          fields: [
            { label: "Room Number", type: "text" },
            { label: "Room Size", type: "number" },
            { label: "Accomodation capacity", type: "number" },
            { label: "Room Rent Price", type: "number" },
          ],
        },
      },
      {
        title: "Building Division (By Rooms)",
        condition: { fieldKey: "rentingType", value: "By Rooms" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Room Size", type: "number" },
          { label: "Room Rent Price", type: "number" },
        ],
      },
      {
        title: "Building Division (By Seats)",
        condition: { fieldKey: "rentingType", value: "By Seats" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Seat Number", type: "text" },
          { label: "Seat Rent Price", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Parking", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
          { label: "Storage Room", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Near Main Road", type: "checkbox" },
          { label: "Nearby Market", type: "checkbox" },
        ],
      },
    ],
  },
  //commercial -> plaza
  {
    category: "Commercial",
    subcategory: "Plaza",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Building", "By Floors", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Plaza Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Total Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Sq. feet", "Sq. meters"],
          },
          { label: "Number of Floors", type: "number" },
          { label: "Number of Shops", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Building Division (Conditional Step)",
        condition: { fieldKey: "rentingType", value: "By Floors" },
        repeatable: true,
        fields: [{ label: "Number of Rooms on this Floor", type: "number" }],
        subunits: {
          title: "Room Information",
          repeatable: true,
          fields: [
            { label: "Room Number", type: "text" },
            { label: "Room Size", type: "number" },
            { label: "Accommodation Capacity", type: "number" },
            { label: "Room Rent Price", type: "number" },
          ],
        },
      },
      {
        title: "Building Division (By Rooms)",
        condition: { fieldKey: "rentingType", value: "By Rooms" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Room Size", type: "number" },
          { label: "Room Rent Price", type: "number" },
        ],
      },
      {
        title: "Building Division (By Seats)",
        condition: { fieldKey: "rentingType", value: "By Seats" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Seat Number", type: "text" },
          { label: "Seat Rent Price", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Elevator", type: "checkbox" },
          { label: "Parking", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
          { label: "Backup Generator", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Near Public Transport", type: "checkbox" },
          { label: "Located in Commercial Hub", type: "checkbox" },
        ],
      },
    ],
  },
  //commercial -> market
  {
    category: "Commercial",
    subcategory: "Market",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Building", "By Floors", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Market Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Total Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Sq. feet", "Sq. meters"],
          },
          { label: "Number of Shops", type: "number" },
          { label: "Number of Floors", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Building Division (Conditional Step)",
        condition: { fieldKey: "rentingType", value: "By Floors" },
        repeatable: true,
        fields: [{ label: "Number of Rooms on this Floor", type: "number" }],
        subunits: {
          title: "Room Information",
          repeatable: true,
          fields: [
            { label: "Room Number", type: "text" },
            { label: "Room Size", type: "number" },
            { label: "Accommodation Capacity", type: "number" },
            { label: "Room Rent Price", type: "number" },
          ],
        },
      },
      {
        title: "Building Division (By Rooms)",
        condition: { fieldKey: "rentingType", value: "By Rooms" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Room Size", type: "number" },
          { label: "Room Rent Price", type: "number" },
        ],
      },
      {
        title: "Building Division (By Seats)",
        condition: { fieldKey: "rentingType", value: "By Seats" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Seat Number", type: "text" },
          { label: "Seat Rent Price", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Parking", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "High Foot Traffic Area", type: "checkbox" },
          { label: "Nearby Commercial Areas", type: "checkbox" },
        ],
      },
    ],
  },
  //commercial -> office floor
  {
    category: "Commercial",
    subcategory: "Office Floor",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Floor", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Office Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Sq. feet", "Sq. meters"],
          },
          { label: "Number of Floors", type: "number" },
          { label: "Number of Rooms/Offices", type: "number" },
        ],
      },
      {
        title: "Room Division (By Rooms)",
        condition: { fieldKey: "rentingType", value: "By Rooms" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Room Size", type: "number" },
          { label: "Room Rent Price", type: "number" },
        ],
      },
      {
        title: "Seat Division (By Seats)",
        condition: { fieldKey: "rentingType", value: "By Seats" },
        repeatable: true,
        fields: [
          { label: "Room Number", type: "text" },
          { label: "Seat Number", type: "text" },
          { label: "Seat Rent Price", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Elevator", type: "checkbox" },
          { label: "Parking", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Furnished/Unfurnished", type: "checkbox" },
          { label: "Nearby Public Transport", type: "checkbox" },
        ],
      },
    ],
  },
  //commercial -> warehouse
  {
    category: "Commercial",
    subcategory: "Warehouse",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Building", "By Floors", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Warehouse Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Sq. feet", "Sq. meters"],
          },
          { label: "Floor Number", type: "number" },
          { label: "Number of Storage Rooms", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Parking", type: "checkbox" },
          { label: "Loading/Unloading Docks", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
          { label: "Fire Safety Equipment", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Near Main Road", type: "checkbox" },
          { label: "Nearby Industrial Area", type: "checkbox" },
        ],
      },
    ],
  },
  //commercial -> factory
  {
    category: "Commercial",
    subcategory: "Factory",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Building", "By Floors", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Factory Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Sq. feet", "Sq. meters"],
          },
          { label: "Number of Floors", type: "number" },
          { label: "Number of Production Units", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Parking", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
          { label: "Fire Safety Equipment", type: "checkbox" },
          { label: "Storage Facility", type: "checkbox" },
          { label: "Staff Quarters", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Near Industrial Area", type: "checkbox" },
          { label: "Nearby Transport Routes", type: "checkbox" },
        ],
      },
    ],
  },
  //healthcare -> hospital
  {
    category: "Healthcare",
    subcategory: "Hospital",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Building", "By Floors", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Hospital Name/Title", type: "text" }, // Renamed for consistency
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Sq. feet", "Sq. meters"],
          },
          { label: "Number of Floors", type: "number" },
          { label: "Number of Wards", type: "number" }, // Hospital-specific field
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Parking", type: "checkbox" },
          { label: "Waiting Area", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Cafeteria", type: "checkbox" },
          { label: "Pharmacy", type: "checkbox" }, // Hospital-specific
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Backup Generator", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Ambulance Bay", type: "checkbox" }, // Hospital-specific
          { label: "Nearby Public Transport", type: "checkbox" },
          { label: "Fire Safety System", type: "checkbox" },
        ],
      },
    ],
  },
  //education -> school
  {
    category: "Educational",
    subcategory: "School",
    steps: [
      {
        title: "Property Type Selection",
        fields: [
          {
            label: "How would you like to rent your asset?",
            type: "select",
            options: ["Entire Building", "By Floors", "By Rooms", "By Seats"],
            key: "rentingType",
          },
        ],
      },
      {
        title: "Basic Information",
        fields: [
          { label: "Property Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Sq. feet", "Sq. meters"],
          },
          { label: "Number of Floors", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "School Facilities",
        fields: [
          { label: "Number of Classrooms", type: "number" },
          { label: "Laboratories", type: "checkbox" },
          { label: "Library", type: "checkbox" },
          { label: "Playground", type: "checkbox" },
          { label: "Auditorium", type: "checkbox" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Parking", type: "checkbox" },
          { label: "Cafeteria", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Nearby Public Transport", type: "checkbox" },
          { label: "Sports Facilities", type: "checkbox" },
          { label: "Fire Safety System", type: "checkbox" },
        ],
      },
    ],
  },
];

export default propertyFormData;
