import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./manageAdmins.module.css";
import { Checkbox } from "@mui/material";
import axios from "axios";
import { URL } from "../../../../route";
import { toast } from "react-toastify";
import { useAuth } from "../../../../context/AuthContext";

function ManageAdmins() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [selectedListings, setSelectedListings] = useState([]);
  const [pendingManagers, setPendingManagers] = useState([]);
  const [pendingListings, setPendingListings] = useState([]);
  const [error, setError] = useState(null);
  const [adminsData, setAdminsData] = useState([]);
  const [loadingButtons, setLoadingButtons] = useState({});

  const [searchQuery, setSearchQuery] = useState("");

  // Fetch data on component mount
  useEffect(() => {
    const fetchPendingManagers = async () => {
      try {
        const pendingManagersResponse = await axios.get(
          `${URL}/api/v1/auth/superAdmin/pending-managers`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const data = pendingManagersResponse.data.data;
        setPendingManagers(data);

        window.sessionStorage.setItem("pending-managers", JSON.stringify(data));
      } catch (error) {
        console.error("Error fetching pending managers:", error);
        setError(
          error.response?.data?.message || "Failed to fetch pending managers"
        );
      }
    };

    const fetchActiveAdmins = async () => {
      try {
        const activeAdminsResponse = await axios.get(
          `${URL}/api/v1/auth/superAdmin/getAllManagers`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const data = activeAdminsResponse.data.data;
        setAdminsData(data);

        window.sessionStorage.setItem("admins-data", JSON.stringify(data));
      } catch (error) {
        console.error("Error fetching active admins:", error);
        setError(
          error.response?.data?.message || "Failed to fetch active admins"
        );
      }
    };

    const fetchListings = async () => {
      try {
        const response = await axios.get(`${URL}/api/v1/auth/superAdmin/data`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { type: "allListings" },
        });

        setPendingListings(response.data.data);
      } catch (error) {
        console.error(
          "Error fetching unapproved listings:",
          error.response?.data || error.message
        );
        throw error;
      }
    };
    fetchListings();
    fetchActiveAdmins();
    fetchPendingManagers();
  }, [token]);

  useEffect(() => {
    const storedAdminsData = window.sessionStorage.getItem("admins-data");
    const storedPendingManagers =
      window.sessionStorage.getItem("pending-managers");

    if (storedAdminsData) {
      try {
        setAdminsData(JSON.parse(storedAdminsData));
      } catch (e) {
        console.error("Error parsing admins data:", e);
      }
    }
    if (storedPendingManagers) {
      try {
        setPendingManagers(JSON.parse(storedPendingManagers));
      } catch (e) {
        console.error("Error parsing pending managers data:", e);
      }
    }
  }, []);

  const activeAdmins = adminsData.filter((admin) => admin.status === "active");
  const otherAdmins = adminsData.filter(
    (admin) => admin.status !== "active" && admin.status !== ""
  );

  // Handle search
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Consolidated function to handle listings actions
  const manageListings = async (action, listingIds) => {
    try {
      const ids = Array.isArray(listingIds) ? listingIds : [listingIds];
      const endpointMap = {
        approve: "approve-listings",
        suspend: "suspend-listings",
        delist: "delist-listings",
        delete: "delete-listings",
        blacklist: "blacklist-listings",
      };
      const response = await axios.patch(
        `${URL}/api/v1/auth/superAdmin/${endpointMap[action]}`,
        { listingIds: ids },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        toast.success(`Listings ${action}d successfully!`);

        setPendingListings((prevListings) =>
          prevListings.filter((listing) => !ids.includes(listing._id))
        );
        setSelectedListings([]);
      }
    } catch (error) {
      console.error(`Error in ${action}ing listings:`, error);
      toast.error(`Failed to ${action} selected listings.`);
    }
  };

  const handleViewAdmin = (adminId) => {
    navigate(`/manageAdmins/viewAdmin/${adminId}`);
  };

  const handleAcceptRequest = async (id) => {
    setLoadingButtons((prev) => ({ ...prev, [id]: true }));
    try {
      const response = await axios.patch(
        `${URL}/api/v1/auth/superAdmin/acceptManager`,
        { managerId: id }, // Correctly pass the request body
        { headers: { Authorization: `Bearer ${token}` } } // Headers go here
      );

      setPendingManagers((prevManagers) =>
        prevManagers.filter((request) => request._id !== id)
      );
      window.sessionStorage.setItem(
        "pending-managers",
        JSON.stringify(pendingManagers.filter((request) => request._id !== id))
      );
      toast.success("Manager request accepted successfully!");
    } catch (error) {
      console.error("Error accepting request:", error);
      toast.error(
        error.response?.data?.message || "Failed to accept manager request."
      );
    } finally {
      setLoadingButtons((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleDeleteRequest = async (id) => {
    setLoadingButtons((prev) => ({ ...prev, [id]: true }));
    try {
      await axios.delete(`${URL}/api/v1/auth/superAdmin/deleteManager`, {
        headers: { Authorization: `Bearer ${token}` }, // Add headers here
        data: { managerId: id }, // Correctly pass the request body
      });

      setPendingManagers((prevManagers) =>
        prevManagers.filter((request) => request._id !== id)
      );
      toast.success("Manager request deleted successfully!");
    } catch (error) {
      console.error("Error deleting request:", error);
      toast.error(
        error.response?.data?.message || "Failed to delete manager request."
      );
    } finally {
      setLoadingButtons((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedListings(pendingListings.map((listing) => listing._id));
    } else {
      setSelectedListings([]);
    }
  };

  const handleSelectListing = (id) => {
    setSelectedListings((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((listingId) => listingId !== id)
        : [...prevSelected, id]
    );
  };

  const filteredListings = pendingListings.filter(
    (listing) =>
      listing["Shop Name/Title"]?.toLowerCase().includes(searchQuery) ||
      listing.email?.toLowerCase().includes(searchQuery)
  );

  return (
    <div className={styles.manageAdminsWrapper}>
      <h1 className={styles.pageTitle}>Manage Admins</h1>

      <div className={styles.manageAdminsWrapper}>
        <h1 className={styles.pageTitle}>Manage Admins</h1>
        {/* Pending Managers Section */}
        <div>
          <h2 className={styles.sectionTitle}>Pending Managers</h2>
          {pendingManagers?.length === 0 ? (
            <p className={styles.noPendingManagers}>
              No pending manager requests.{pendingManagers}
            </p>
          ) : (
            <div className={styles.pendingManagersGrid}>
              {pendingManagers?.map((manager) => (
                <div key={manager._id} className={styles.managerCard}>
                  <p>
                    <strong>Name:</strong> {manager.name || "No Name"}
                  </p>
                  <p>
                    <strong>Email:</strong> {manager.email || "No Email"}
                  </p>
                  <div className={styles.managerCardActions}>
                    <button
                      className={styles.acceptButton}
                      onClick={() => handleAcceptRequest(manager._id)}
                      disabled={loadingButtons[manager._id]}
                    >
                      {loadingButtons[manager._id] ? "Processing..." : "Accept"}
                    </button>
                    <button
                      className={styles.deleteButton}
                      onClick={() => handleDeleteRequest(manager._id)}
                      disabled={loadingButtons[manager._id]}
                    >
                      {loadingButtons[manager._id] ? "Processing..." : "Delete"}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Admins Table */}
        <div>
          <h2 className={styles.sectionTitle}>Admins</h2>
          {adminsData.length === 0 ? (
            <p className={styles.noAdmins}>No admins available.</p>
          ) : (
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Admin Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {adminsData
                  .filter(
                    (admin) =>
                      admin.name.toLowerCase().includes(searchQuery) ||
                      admin.email.toLowerCase().includes(searchQuery)
                  )
                  .map((admin) => (
                    <tr key={admin._id}>
                      <td>{admin.name}</td>
                      <td>{admin.email}</td>
                      <td>{admin.status || "No Status"}</td>
                      <td>
                        <button
                          className={styles.viewButton}
                          onClick={() => handleViewAdmin(admin._id)}
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* Listings Table */}
      <div>
        <h2 className={styles.sectionTitle}>Listings</h2>
        <div className={styles.bulkActions}>
          <button
            className={styles.acceptButton}
            onClick={() => manageListings("approve", selectedListings)}
          >
            Approve Selected
          </button>
          <button
            className={styles.suspendButton}
            onClick={() => manageListings("suspend", selectedListings)}
          >
            Suspend Selected
          </button>
          <button
            className={styles.delistButton}
            onClick={() => manageListings("delist", selectedListings)}
          >
            Delist Selected
          </button>
          <button
            className={styles.deleteButton}
            onClick={() => manageListings("blacklist", selectedListings)}
          >
            Blacklist
          </button>
          <button
            className={styles.deleteButton}
            onClick={() => manageListings("delete", selectedListings)}
          >
            Delete Selected
          </button>
        </div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={
                    selectedListings.length > 0 &&
                    selectedListings.length === filteredListings.length
                  }
                  onChange={handleSelectAll}
                />
              </th>
              <th>_id</th>
              <th>Listing Name</th>
              <th>Owner</th>
              <th>Status</th>
              <th>Actions</th>
              <th>Revenue</th>
            </tr>
          </thead>
          <tbody>
            {filteredListings.map((listing) => (
              <tr key={listing._id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedListings.includes(listing._id)}
                    onChange={() => handleSelectListing(listing._id)}
                  />
                </td>
                <td>{listing._id}</td>
                <td>
                  <button
                    className={styles.listingLink}
                    onClick={() => navigate(`/viewProperty/${listing._id}`)}
                  >
                    {listing["Shop Name/Title"] || "No Title"}
                  </button>
                </td>
                <td>{listing.email || "No Email"}</td>
                <td>
                  {listing.propertyStatus?.approvedBySuperAdmin
                    ? listing.propertyStatus?.status
                    : "No Status"}
                </td>
                <td>
                  <button
                    className={styles.acceptButton}
                    onClick={() => manageListings("approve", [listing._id])}
                  >
                    Approve
                  </button>
                  <button
                    className={styles.suspendButton}
                    onClick={() => manageListings("suspend", [listing._id])}
                  >
                    Suspend
                  </button>
                  <button
                    className={styles.delistButton}
                    onClick={() => manageListings("delist", [listing._id])}
                  >
                    Delist
                  </button>
                  <button
                    className={styles.delistButton}
                    onClick={() => manageListings("blacklist", [listing._id])}
                  >
                    Blacklist
                  </button>
                  <button
                    className={styles.deleteButton}
                    onClick={() => manageListings("delete", [listing._id])}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ManageAdmins;
