import React, { useState } from "react";
import SpecialDot from "../specialDot/specialDot";
import styles from "./footer.module.css";
import {
  FbIcon,
  FillIcon,
  InstagramIcon,
  TwitterIcon,
  WtsapIcon,
} from "../svgs/rentpal";

export default function Footer() {
  const getThisYear = new Date().getFullYear();
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerColumns}>
          <div className={styles.footerColumn}>
            <h2>Support</h2>
            <p>Help Center</p>
            <p>Rentcover</p>
            <p>Anti-Discrimination</p>
            <p>Daily Support</p>
            <p>Cancellation Options</p>
          </div>
          <div className={styles.footerColumn}>
            <h2>Managing</h2>
            <p>Manage your home</p>
            <p>Rentcover for owners</p>
            <p>Managing resources</p>
            <p>Community forums</p>
          </div>
          <div className={styles.footerColumn}>
            <h2>RentPal</h2>
            <p>About us</p>
            <p>Contact us</p>
            <p>Jobs & Careers</p>
            <p>News Center</p>
            <p>Investors</p>
          </div>
        </div>
        <div className={styles.dash}></div>
        <div className={`${styles.footerColumns} ${styles.lower}`}>
          <div className={`${styles.footerColumn} ${styles.lowerRowFirst}`}>
            <span>&#169; {getThisYear} RentPal, inc.</span>
            <FillIcon />
            <span>Terms</span> <FillIcon />
            <span>Sitemap</span> <FillIcon />
            <span>Privacy</span>
            <FillIcon />
            <span>Your Privacy Choices</span>
          </div>
          <div className={styles.iconContainer}>
            <FbIcon />
            <WtsapIcon />
            <InstagramIcon />
            <TwitterIcon />
          </div>
        </div>
      </div>
    </>
  );
}
