import React, { useState, useRef } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styles from "./AddResidents.module.css";

const Step1 = ({
  formik,
  handleSearchChange,
  searchResults,
  goToNextStep,
  onSelectResident,
}) => {
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const searchRef = useRef(null);

  const handleNewResidentClick = () => {
    navigate("/signup");
  };

  const handleExistingResidentClick = (residentId) => {
    formik.setFieldValue("residentType", "existing");
    formik.setFieldValue("selectedResident", residentId);
    onSelectResident(residentId);
    // console.log(`selected resident ID in step 1: ${residentId}`);
    goToNextStep();
  };

  const handleClickOutside = (event) => {
    if (
      searchRef.current &&
      !searchRef.current.contains(event.target) &&
      !event.target.closest(".searchInput")
    ) {
      setShowSearch(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <h2>Step 1: Add or Search Resident</h2>
      <div
        className={`${styles.card} ${styles.clickable}`}
        onClick={handleNewResidentClick}
      >
        <h1>Add a New Resident</h1>
        <FaPlus className={styles.icon} />
      </div>

      <div
        ref={searchRef}
        className={`${styles.card} ${styles.searchContainer}`}
        onClick={() => setShowSearch(true)}
      >
        {!showSearch ? (
          <>
            <h1>Search Existing Resident</h1>
            <FaSearch className={styles.icon} />
          </>
        ) : (
          <div className={styles.searchWrapper}>
            <input
              type="text"
              placeholder="Search by name or ID"
              onChange={handleSearchChange}
              className={styles.searchInput}
              autoFocus
            />
            {searchResults.length > 0 && (
              <ul className={styles.searchResults}>
                {searchResults.map((resident) => (
                  <li
                    key={resident.id}
                    className={styles.searchResultItem}
                    onClick={() => handleExistingResidentClick(resident._id)}
                  >
                    {resident.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Step1;
