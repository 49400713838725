import React, { useState } from "react";
import { reviews } from "./ReviewsData";
import styles from "./Reviews.module.css";
import { CloseIcon } from "../../../../components/svgs/rentpal";

const Reviews = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [displayedReviews, setDisplayedReviews] = useState(4); // To track the number of displayed reviews

  const handleDialogToggle = () => {
    setIsDialogOpen((prev) => !prev);
  };

  return (
    <div className={styles.reviewsContainer}>
      <h2>{`${reviews.length} Reviews`}</h2>
      <p className={styles.averageRating}>
        Average Rating will appear after 3-4 reviews
      </p>
      <div className={styles.reviewsGrid}>
        {reviews.slice(0, displayedReviews).map((review) => (
          <div key={review.id} className={styles.reviewsCard}>
            <div className={styles.reviewsHeader}>
              <img
                src={review.image}
                alt={`Image of ${review.name}`}
                className={styles.avatar}
              />
              <div>
                <h4>{review.name}</h4>
                <p className={styles.memberInfo}>
                  member Since {review.memberSince}
                </p>
              </div>
            </div>
            <div className={styles.reviewsRating}>
              {"★".repeat(review.rating)} {"☆".repeat(5 - review.rating)}
              <span className={styles.timeAgo}>{review.timeAgo}</span>
            </div>
            <p className={styles.reviewsContent}>{review.content}</p>
          </div>
        ))}
      </div>

      <button onClick={handleDialogToggle} className={styles.showAllReviews}>
        <h1>Show all {reviews.length} reviews</h1>
      </button>

      <div>
        {isDialogOpen && (
          <div className={styles.reviewsDialogOverlay}>
            <div className={styles.reviewsDialogContent}>
              <button className={styles.closeBtn} onClick={handleDialogToggle}>
                <CloseIcon />
              </button>
              <div className={styles.reviewsGrid}>
                {reviews.map((review) => (
                  <div key={review.id} className={styles.reviewsCard}>
                    <div className={styles.reviewsHeader}>
                      <img
                        src={review.image}
                        alt={`Image of ${review.name}`}
                        className={styles.avatar}
                      />
                      <div>
                        <h4>{review.name}</h4>
                        <p className={styles.memberInfo}>
                          member Since {review.memberSince}
                        </p>
                      </div>
                    </div>
                    <div className={styles.reviewsRating}>
                      {"★".repeat(review.rating)}{" "}
                      {"☆".repeat(5 - review.rating)}
                      <span className={styles.timeAgo}>{review.timeAgo}</span>
                    </div>
                    <p className={styles.reviewsContent}>{review.content}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Reviews;
