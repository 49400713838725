import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./viewAdmin.module.css";
import ConfirmationModal from "../../../../../components/confirmationModal/confirmationModal";
import { URL } from "../../../../../route";
import { useAuth } from "../../../../../context/AuthContext";

function ViewAdmin() {
  const { id } = useParams(); // Retrieve the admin ID (_id) (_id) from the URL
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useAuth();

  useEffect(() => {
    // Fetch admins data from sessionStorage
    const storedAdminsData = window.sessionStorage.getItem("admins-data");
    if (storedAdminsData) {
      const parsedAdminsData = JSON.parse(storedAdminsData);
      const foundAdmin = parsedAdminsData.find(
        (admin) => admin._id === id // Match by _id
      );
      setAdmin(foundAdmin);
    }
  }, [id]);

  if (!admin) {
    return (
      <div className={styles.errorWrapper}>
        <h2>Admin Not Found</h2>
        <button onClick={() => navigate(-1)} className={styles.backButton}>
          Go Back
        </button>
      </div>
    );
  }

  const handleAction = (action) => {
    setAction(action);
    setIsModalOpen(true);
  };

  const handleConfirmAction = async () => {
    setIsModalOpen(false);
    setIsLoading(true);
    setError(null);

    try {
      if (action === "Delete") {
        // DELETE request to delete the manager
        const response = await axios.delete(
          `${URL}/api/v1/auth/superAdmin/deleteManager/${admin._id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.success) {
          console.log("Admin deleted successfully.");
          navigate(-1); // Navigate back after successful deletion
        } else {
          console.error("Error:", response.data.message);
          setError(response.data.message);
        }
      } else {
        // PATCH request for Suspend, Block, or Activate actions
        const statusMap = {
          Suspend: "suspended",
          Block: "blocked",
          Active: "active",
        };

        const updatedStatus = statusMap[action];

        const response = await axios.patch(
          `${URL}/api/v1/auth/superAdmin/changeManagerStatus/${admin._id}`,
          { status: updatedStatus },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.success) {
          setAdmin((prevAdmin) => ({
            ...prevAdmin,
            status: updatedStatus,
          }));
          console.log(`${action} successful for admin:`, response.data.message);
        } else {
          console.error("Error:", response.data.message);
          setError(response.data.message);
        }
      }
    } catch (error) {
      console.error(
        "Error processing admin action:",
        error.response?.data || error.message
      );
      setError("An error occurred while processing the admin action.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelAction = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.viewAdminWrapper}>
      <button className={styles.backButton} onClick={() => navigate(-1)}>
        &larr; Back
      </button>

      <h1 className={styles.adminName}>{admin.name}</h1>
      <div className={styles.detailsContainer}>
        <p>
          <strong>Status:</strong> {admin.status}
        </p>
        <p>
          <strong>Total Properties:</strong> {admin.totalProperties || "N/A"}
        </p>
        <p>
          <strong>Total Revenue:</strong> Rs.
          {admin.totalRevenue?.toLocaleString() || 0}/-
        </p>
        <p>
          <strong>Active Devices:</strong> {admin.activeDevices || "None"}
        </p>

        {/* Action buttons */}
        <div className={styles.actionButtons}>
          {admin.status === "active" ? (
            <>
              <button
                className={styles.suspendButton}
                onClick={() => handleAction("Suspend")}
              >
                Suspend
              </button>
              <button
                className={styles.blockButton}
                onClick={() => handleAction("Block")}
              >
                Block
              </button>
            </>
          ) : (
            <button
              className={styles.activeButton}
              onClick={() => handleAction("Active")}
            >
              Activate
            </button>
          )}
          <button
            className={styles.deleteButton}
            onClick={() => handleAction("Delete")}
          >
            Delete
          </button>
        </div>
      </div>

      {/* Error Message */}
      {error && <p className={styles.error}>{error}</p>}

      {/* Loading Indicator */}
      {isLoading && <p className={styles.loading}>Processing...</p>}

      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isModalOpen}
        message={`Are you sure you want to ${action} this admin?`}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelAction}
      />
    </div>
  );
}

export default ViewAdmin;
