import React from "react";
import { useNavigate } from "react-router-dom";
import { GoBackIcon } from "../../../../components/svgs/rentpal";
import YourListings from "../../../../components/yourListings/YourListings";

const Listings = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/dashboard");
  };

  return (
    <div style={{ display: "flex", gap: "1vw", padding: "0 3vw" }}>
      <div
        onClick={handleGoBack}
        style={{ cursor: "pointer", height: "fit-content" }}
      >
        <GoBackIcon />
      </div>
      <YourListings />
    </div>
  );
};

export default Listings;
