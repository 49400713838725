import { useState } from "react";
import React, { useNavigate } from "react-router-dom";
import styles from "./ResidentManagement.module.css";
import YourResidents from "../../../../components/yourResidents/YourResidents";
import ResidentsStats from "../../../../components/residentsStats/ResidentsStats";
import ResidentRequests from "../../../../components/residentRequests/ResidentRequests";
import { GoBackIcon } from "../../../../components/svgs/rentpal";
import CollapsibleSection from "../../../../components/collapsable/CollapsibleSection";

function ResidentManagement() {
  const navigate = useNavigate();
  const [openCollapses, setOpenCollapses] = useState([]);

  const handleOpenCollapse = (id) => {
    if (openCollapses.includes(id)) {
      setOpenCollapses((prevState) => prevState.filter((p) => p !== id));
    } else {
      setOpenCollapses((prevState) => [...prevState, id]);
    }
  };

  const handleGoBack = () => {
    navigate("/dashboard");
  };
  return (
    <div style={{ display: "flex", justifyContent: "center", gap: "1vw" }}>
      <div
        onClick={handleGoBack}
        style={{ cursor: "pointer", height: "fit-content" }}
      >
        <GoBackIcon />
      </div>
      <div>
        <h2 style={{ marginTop: "0" }}>Resident Management</h2>
        <div
          style={{
            display: "flex",
            gap: "2vw",
            alignItems: "center",
            marginBottom: "2vh",
          }}
        >
          <ResidentsStats />
          <ResidentRequests />
        </div>
        <YourResidents />
        <CollapsibleSection
          id="c1"
          title="Room 1"
          openCollapses={openCollapses}
          handleOpen={handleOpenCollapse}
        >
          <p>Muhammad Talha Munir</p>
          <CollapsibleSection
            id="c2"
            title="Room 2"
            openCollapses={openCollapses}
            handleOpen={handleOpenCollapse}
          >
            <p>Muhammad Talha Munir</p>
            <p>+92 311 5472492</p>
            <p style={{ color: "green", fontWeight: "bold" }}>Active</p>
          </CollapsibleSection>
        </CollapsibleSection>
      </div>
    </div>
  );
}

export default ResidentManagement;
