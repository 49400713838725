import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import styles from "./YourResidents.module.css";
import ListingImg from "../../assets/images/house_img.png";
import { PlusIcon, SearchIcon2, ThreeDotIcon } from "../svgs/rentpal";
import { URL } from "../../route";

const YourResidents = ({ hideAddResidents, onSelectVacant }) => {
  const [openMenuId, setOpenMenuId] = useState(null);
  const [listings, setListings] = useState([]);
  const [expandedListings, setExpandedListings] = useState({});
  const [expandedFloors, setExpandedFloors] = useState({});
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  const { authData } = useAuth();
  const navigate = useNavigate();
  const fetchListings = async (newPage = 1) => {
    if (loadingMore) return;
    setLoadingMore(true);

    try {
      const response = await axios.get(`${URL}/api/v1/auth/admin/get-data`, {
        params: {
          type: "allListings",
          uniqueId: authData.data.uniqueId,
          filter: null,
          sortBy: "createdAt",
          page: newPage,
          limit: 10,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authData.token}`,
        },
      });

      if (response.status === 200) {
        setListings((prev) => {
          const newListings = response.data.data.filter(
            (newItem) =>
              !prev.some((existingItem) => existingItem._id === newItem._id)
          );
          return [...prev, ...newListings];
        });
        setPage(newPage);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching listings:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchListings();
  }, [authData.token, authData.data.uniqueId]);

  const toggleExpandListing = (listingId) => {
    setExpandedListings((prev) => ({
      ...prev,
      [listingId]: !prev[listingId],
    }));
  };

  const toggleMenu = (id) => {
    setOpenMenuId((prev) => (prev === id ? null : id));
  };

  const toggleExpandFloor = (listingId, floorIndex) => {
    setExpandedFloors((prev) => ({
      ...prev,
      [`${listingId}-${floorIndex}`]: !prev[`${listingId}-${floorIndex}`],
    }));
  };

  const handleRowSelect = (listing, floor, room) => {
    if (!room || !room._id) {
      console.error("Invalid room data:", room);
      return;
    }

    if (!room.occupantName) {
      console.log("Selected room:", {
        buildingId: listing._id,
        floorId: floor._id,
        roomId: room._id,
      });

      if (onSelectVacant) {
        onSelectVacant({
          buildingId: listing._id,
          floorId: floor._id,
          roomId: room._id,
        });
      } else {
        console.error("onSelectVacant is not provided");
      }
    }
  };

  return (
    <div className={styles.yourResidentsContainer}>
      <div className={styles.residentsContentContainer}>
        <div className={styles.residentsHeaderContainer}>
          <h2>Your Residents</h2>
          <div style={{ display: "flex", alignItems: "center", gap: "1vw" }}>
            <SearchIcon2 />
            {!hideAddResidents && (
              <div
                className={styles.residentsAdd}
                onClick={() => navigate(`/dashboard/add-resident`)}
              >
                <p>Add Resident</p>
                <PlusIcon />
              </div>
            )}
          </div>
        </div>
        <div className={styles.residentsHeader}>
          <span>Listing</span>
          <span>Resident Name</span>
          <span>Phone No.</span>
          <span>Status</span>
          <span>Actions</span>
        </div>
        {listings.map((listing, listingIndex) => {
          const listingNameField =
            Object.keys(listing).find((key) => key.endsWith("Name/Title")) ||
            "Unknown";

          // Determine row color: even rows are white, odd rows are light gray
          const rowStyle =
            listingIndex % 2 === 0 ? styles.rowWhite : styles.rowGray;

          return (
            <React.Fragment key={listing._id}>
              {/* Listing row */}
              <div
                className={`${styles.residentsItem} ${rowStyle}`}
                onClick={() => toggleExpandListing(listing._id)}
              >
                {/* First column: Listing Image + Name */}
                <div className={styles.residentsInfo}>
                  <img
                    src={
                      listing.buildingImages.length > 0
                        ? listing.buildingImages[0].url
                        : ListingImg
                    }
                    alt={listing[listingNameField] || "Unknown"}
                    className={styles.residentsImage}
                  />
                  <h1>{listing[listingNameField] || "Unknown"}</h1>
                </div>

                {/* Empty column for Resident Name (Floors will be inside) */}
                <div></div>

                {/* Empty columns for Phone No, Status, and Actions */}
                <div></div>
                <div></div>
                <div></div>
              </div>

              {/* Floor Rows (inside Resident Name column) */}
              {expandedListings[listing._id] &&
                listing.buildingFloors.map((floor, floorIndex) => {
                  const floorRowStyle =
                    floorIndex % 2 === 0 ? styles.rowWhite : styles.rowGray;

                  return (
                    <React.Fragment key={floorIndex}>
                      {/* Floor Row */}
                      <div
                        className={`${styles.residentsItem} ${floorRowStyle}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleExpandListing(
                            `${listing._id}-floor-${floorIndex}`
                          );
                        }}
                      >
                        {/* First column: Arrow icon before Floor text */}
                        <div className={styles.residentsInfo}>
                          <span className={styles.arrowIcon}>
                            {expandedListings[
                              `${listing._id}-floor-${floorIndex}`
                            ]
                              ? "⬇"
                              : "▶"}
                          </span>
                          <p>Floor {floorIndex + 1}</p>
                        </div>

                        {/* Empty columns for Phone No, Status, and Actions */}
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>

                      {/* Room Rows (indented under Floor) */}
                      {expandedListings[`${listing._id}-floor-${floorIndex}`] &&
                        floor.roomInfo.map((room, roomIndex) => {
                          const roomRowStyle =
                            roomIndex % 2 === 0
                              ? styles.rowWhite
                              : styles.rowGray;

                          return (
                            <div
                              key={roomIndex}
                              className={`${styles.residentsItem} ${roomRowStyle}`}
                              onClick={() =>
                                handleRowSelect(listing, floor, room)
                              }
                            >
                              {/* First column: Empty for indentation */}
                              <div></div>

                              {/* Second column: Indented Room Details */}
                              <div className={styles.roomDetails}>
                                <p>
                                  <strong>Room {room.roomNumber}:</strong>{" "}
                                  {room.occupantName || "Vacant"}
                                </p>
                              </div>

                              {/* Third column: Phone No */}
                              <div>{room.occupantPhone || "-"}</div>

                              {/* Fourth column: Status */}
                              <div>
                                <span className={styles.status}>
                                  {room.occupantStatus || "N/A"}
                                </span>
                              </div>

                              {/* Fifth column: Actions */}
                              <div className={styles.actionButtons}>
                                <span
                                  onClick={() =>
                                    toggleMenu(
                                      `${listing._id}-${floorIndex}-${roomIndex}`
                                    )
                                  }
                                >
                                  <ThreeDotIcon />
                                </span>
                                {openMenuId ===
                                  `${listing._id}-${floorIndex}-${roomIndex}` && (
                                  <div className={styles.threeDotMenu}>
                                    <div className={styles.menuItems}>Move</div>
                                    <div className={styles.menuItems}>
                                      Suspend
                                    </div>
                                    <div className={styles.menuItems}>
                                      Remove
                                    </div>
                                    <div className={styles.menuItems}>
                                      Blacklist
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
            </React.Fragment>
          );
        })}

        {/* Load More Button (outside .map(), but inside the container) */}
        {listings.length > 0 && (
          <button
            className={styles.loadMoreButton}
            onClick={() => fetchListings(page + 1)}
            disabled={loadingMore}
          >
            {loadingMore ? "Loading..." : "Load More"}
          </button>
        )}
      </div>
    </div>
  );
};

export default YourResidents;
