import React, { useState, useEffect } from "react";
import styles from "./Guests.module.css";

const Guests = () => {
  const [guests, setGuests] = useState([]);
  const [guestDetails, setGuestDetails] = useState({
    name: "",
    biometric: null,
    time: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null);

  // Using dummy data instead of API requests
  useEffect(() => {
    // Dummy guest list
    const dummyGuests = [
      {
        id: 1,
        name: "John Doe",
        biometric: "scanned_biometric_data_abc123",
        time: "2024-12-01T10:00:00",
      },
      {
        id: 2,
        name: "Jane Smith",
        biometric: "scanned_biometric_data_xyz456",
        time: "2024-12-02T14:00:00",
      },
    ];
    setGuests(dummyGuests);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGuestDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleBiometricScan = () => {
    // Example biometric scan handler
    alert("Biometric scan initiated...");
    // For demonstration, we'll simulate biometric data as a random number.
    setGuestDetails((prevDetails) => ({
      ...prevDetails,
      biometric:
        "scanned_biometric_data_" + Math.random().toString(36).substring(2),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newGuest = {
      ...guestDetails,
      id: guests.length + 1, // Incremental id for simplicity
    };

    if (isUpdating) {
      // Update existing guest
      setGuests((prevGuests) =>
        prevGuests.map((guest) =>
          guest.id === selectedGuest.id ? { ...guest, ...guestDetails } : guest
        )
      );
    } else {
      // Add new guest
      setGuests((prevGuests) => [...prevGuests, newGuest]);
    }

    setGuestDetails({ name: "", biometric: null, time: "" });
    setIsUpdating(false);
    setSelectedGuest(null);
  };

  const handleDelete = (id) => {
    // Delete a guest
    setGuests((prevGuests) => prevGuests.filter((guest) => guest.id !== id));
  };

  const handleEdit = (guest) => {
    setGuestDetails({
      name: guest.name,
      biometric: guest.biometric,
      time: guest.time,
    });
    setIsUpdating(true);
    setSelectedGuest(guest);
  };

  return (
    <div className={styles.addGuestContainer}>
      <h1 className={styles.pageTitle}>Add New Guest</h1>

      <form onSubmit={handleSubmit} className={styles.guestForm}>
        <label className={styles.formLabel}>Name</label>
        <input
          type="text"
          name="name"
          value={guestDetails.name}
          onChange={handleInputChange}
          required
          className={styles.formInput}
        />

        <label className={styles.formLabel}>Biometric Scan</label>
        <button
          type="button"
          onClick={handleBiometricScan}
          className={styles.formButton}
        >
          Start Biometric Scan
        </button>

        <label className={styles.formLabel}>Time for Access</label>
        <input
          type="datetime-local"
          name="time"
          value={guestDetails.time}
          onChange={handleInputChange}
          required
          className={styles.formInput}
        />

        <button type="submit" className={styles.formButton}>
          {isUpdating ? "Update Guest" : "Add Guest"}
        </button>
      </form>

      <h2 className={styles.guestListTitle}>Guest List</h2>
      <ul className={styles.guestList}>
        {guests.map((guest) => (
          <li key={guest.id} className={styles.guestItem}>
            <div>
              <h3>{guest.name}</h3>
              <p>Time: {new Date(guest.time).toLocaleString()}</p>
              <p>Biometric: {guest.biometric}</p>
            </div>
            <div>
              <button
                onClick={() => handleEdit(guest)}
                className={styles.editButton}
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(guest.id)}
                className={styles.deleteButton}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Guests;
