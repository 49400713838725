import HostImage from "../../../../assets/images/host-img.png";
export const reviews = [
  {
    id: 1,
    name: "M Talha",
    memberSince: "2014",
    rating: 5,
    timeAgo: "3 weeks ago",
    image: HostImage,
    content:
      "Book your stay with us and experience The Perfect and Ideal Family Room for a Comfortable & Affordable Stay in the Heart of London in one of the city's most prestigious and charming locations, Earls Court!",
  },
  {
    id: 2,
    name: "M Talha",
    memberSince: "2014",
    rating: 5,
    timeAgo: "3 weeks ago",
    image: HostImage,
    content:
      "Book your stay with us and experience The Perfect and Ideal Family Room for a Comfortable & Affordable Stay in the Heart of London.",
  },
  {
    id: 3,
    name: "M Talha",
    memberSince: "2014",
    rating: 5,
    timeAgo: "3 weeks ago",
    image: HostImage,
    content:
      "Book your stay with us and experience The Perfect and Ideal Family Room for a Comfortable & Affordable Stay in the Heart of London.",
  },
  {
    id: 4,
    name: "M Talha",
    memberSince: "2014",
    rating: 5,
    timeAgo: "3 weeks ago",
    image: HostImage,
    content:
      "Book your stay with us and experience The Perfect and Ideal Family Room for a Comfortable & Affordable Stay in the Heart of London.",
  },
  {
    id: 5,
    name: "M Talha",
    memberSince: "2014",
    rating: 5,
    timeAgo: "3 weeks ago",
    image: HostImage,
    content:
      "Book your stay with us and experience The Perfect and Ideal Family Room for a Comfortable & Affordable Stay in the Heart of London.",
  },
  {
    id: 6,
    name: "M Talha",
    memberSince: "2014",
    rating: 5,
    timeAgo: "3 weeks ago",
    image: HostImage,
    content:
      "Book your stay with us and experience The Perfect and Ideal Family Room for a Comfortable & Affordable Stay in the Heart of London.",
  },
  {
    id: 7,
    name: "M Talha",
    memberSince: "2014",
    rating: 5,
    timeAgo: "3 weeks ago",
    image: HostImage,
    content:
      "Book your stay with us and experience The Perfect and Ideal Family Room for a Comfortable & Affordable Stay in the Heart of London.",
  },
];
