import HostImg from "../../assets/images/host-img.png";
import HouseImg from "../../assets/images/house_img.png";

export const request = [
  {
    id: 1,
    idNumber: "35022-8401193-9",
    name: "Muhammad Talha",
    listing: "Raj's House",
    duration: "3 months on Rentpal",
    profileImg: HostImg,
    listImg: HouseImg,
  },
  {
    id: 2,
    idNumber: "35022-8401193-9",
    name: "Muhammad Talha",
    listing: "Raj's House",
    duration: "3 months on Rentpal",
    profileImg: HostImg,
    listImg: HouseImg,
  },
  {
    id: 3,
    idNumber: "35022-8401193-9",
    name: "Muhammad Talha",
    listing: "Raj's House",
    duration: "3 months on Rentpal",
    profileImg: HostImg,
    listImg: HouseImg,
  },
  {
    id: 4,
    idNumber: "35022-8401193-9",
    name: "Muhammad Talha",
    listing: "Raj's House",
    duration: "3 months on Rentpal",
    profileImg: HostImg,
    listImg: HouseImg,
  },
  {
    id: 5,
    idNumber: "35022-8401193-9",
    name: "Muhammad Talha",
    listing: "Raj's House",
    duration: "3 months on Rentpal",
    profileImg: HostImg,
    listImg: HouseImg,
  },
  {
    id: 6,
    idNumber: "35022-8401193-9",
    name: "Muhammad Talha",
    listing: "Raj's House",
    duration: "3 months on Rentpal",
    profileImg: HostImg,
    listImg: HouseImg,
  },
];
