import React from "react";
import {
  AirConditionerIcon,
  ElectricityIcon,
  GasIcon,
  ParkingIcon,
  SharedKitchenIcon,
  StorageRoomIcon,
  WaterSupplyIcon,
  WifiIcon,
} from "../../../components/svgs/rentpal";

export const AmenitiesProperty = [
  {
    icon: <ElectricityIcon />,
    label: "Electricity",
  },
  {
    icon: <WifiIcon />,
    label: "Wifi",
  },
  {
    icon: <AirConditionerIcon />,
    label: "Air Conditioner",
  },
  {
    icon: <WaterSupplyIcon />,
    label: "Water Supply",
  },
  {
    icon: <GasIcon />,
    label: "Gas",
  },
  {
    icon: <ParkingIcon />,
    label: "Parking",
  },
  {
    icon: <StorageRoomIcon />,
    label: "Storage room",
  },
  {
    icon: <SharedKitchenIcon />,
    label: "Shared kitchen",
  },
];
