import axios from "axios";
import React, { useEffect, useState } from "react";
import { URL } from "../../../route";
import { useAuth } from "../../../context/AuthContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Step3 = ({ formik }) => {
  const { values } = formik;
  const [residentInfo, setResidentInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const { authData } = useAuth();

  useEffect(() => {
    const fetchResidentInfo = async () => {
      if (!values.selectedResidentId) return;

      try {
        setLoading(true);
        const response = await axios.get(`${URL}/api/v1/auth/admin/get-data`, {
          params: {
            type: "residentInfo",
            uniqueId: authData.data.uniqueId,
            residentId: values.selectedResidentId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authData.token}`,
          },
        });

        if (response.status === 200) {
          setResidentInfo(response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching resident info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResidentInfo();
  }, [values.selectedResidentId, authData.token, authData.data.uniqueId]);

  return (
    <div>
      <h2>Step 3: Final Checklist</h2>

      {/* Resident Info */}
      <div>
        <h3>Resident Information</h3>
        <p>
          <strong>Name:</strong>{" "}
          {loading ? <Skeleton width={150} /> : residentInfo?.name || "N/A"}
        </p>
        <p>
          <strong>Email:</strong>{" "}
          {loading ? <Skeleton width={200} /> : residentInfo?.email || "N/A"}
        </p>
        <p>
          <strong>Phone:</strong>{" "}
          {loading ? <Skeleton width={120} /> : residentInfo?.phone || "N/A"}
        </p>
      </div>

      {/* Seat Selection Info */}
      <div>
        <h3>Selected Seat</h3>
        <p>
          <strong>Building:</strong>{" "}
          {values.selectedVacantSpace?.buildingId || "N/A"}
        </p>
        <p>
          <strong>Floor:</strong> {values.selectedVacantSpace?.floorId || "N/A"}
        </p>
        <p>
          <strong>Room:</strong> {values.selectedVacantSpace?.roomId || "N/A"}
        </p>
      </div>

      {/* Checklist */}
      <ul>
        <li>
          <input
            type="checkbox"
            name="checklistConfirmed"
            checked={values.checklistConfirmed}
            onChange={formik.handleChange}
          />
          Resident information is correct
        </li>
        <li>
          <input
            type="checkbox"
            name="documentsCollected"
            checked={values.documentsCollected}
            onChange={formik.handleChange}
          />
          Required documents have been collected
        </li>
        <li>
          <input
            type="checkbox"
            name="rentConfirmed"
            checked={values.rentConfirmed}
            onChange={formik.handleChange}
          />
          Rent payment is confirmed
        </li>
      </ul>
    </div>
  );
};

export default Step3;
