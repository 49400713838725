import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";
import styles from "./AddResidents.module.css";
import { toast } from "react-toastify";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { URL } from "../../../route";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

// Validation Schema
const validationSchema = Yup.object({
  residentType: Yup.string().required("Please choose an option"),
  selectedVacantSpace: Yup.object().shape({
    buildingId: Yup.string().required("Building is required"),
    floorId: Yup.string().required("Floor is required"),
    roomId: Yup.string().required("Room is required"),
  }),
});

const AddResidents = () => {
  const [step, setStep] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const { authData } = useAuth();
  const [selectedResident, setSelectedResident] = useState(null); // ✅ Store selected resident
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      residentType: "",
      selectedResident: "",
      checklistConfirmed: false,
      selectedVacantSpace: {
        buildingId: "",
        floorId: "",
        roomId: "",
      },
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true); // ✅ Disable button

      const controller = new AbortController(); // ✅ Create an AbortController
      const timeout = setTimeout(() => {
        controller.abort(); // ✅ Abort request after 5s
      }, 4000);

      try {
        const response = await Promise.race([
          axios.post(`${URL}/api/v1/auth/admin/add-resident`, values, {
            headers: {
              Authorization: `Bearer ${authData.token}`,
              "Content-Type": "application/json",
            },
            signal: controller.signal, // ✅ Attach signal to request
          }),
        ]);

        toast.success("Resident added successfully!");
        navigate("/dashboard");
      } catch (error) {
        if (axios.isCancel(error)) {
          toast.error("Request timed out! Please try again.");
        } else {
          toast.error(
            `Failed to add resident. Reason: ${
              error.response?.data?.message || error.message
            }`
          );
        }
      } finally {
        clearTimeout(timeout); // ✅ Clear timeout to prevent memory leaks
        setIsSubmitting(false); // ✅ Re-enable button
      }
    },
  });

  const handleSelectResident = (resident) => {
    setSelectedResident(resident);
  };
  const onSelectVacant = (e) => {
    console.log("vancant selected", e);
  };
  const goToNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };
  const handleSearchChange = async (e) => {
    const query = e.target.value;
    formik.setFieldValue("residentType", "existing");

    if (query.length > 2) {
      try {
        const response = await axios.get(
          `${URL}/api/v1/auth/search-residents`,
          {
            params: { query },
          }
        );
        setSearchResults(response.data);
      } catch (error) {
        console.error("Error fetching residents:", error);
      }
    } else {
      setSearchResults([]);
    }
  };

  // Dynamic step rendering

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            formik={formik}
            handleSearchChange={handleSearchChange}
            searchResults={searchResults}
            goToNextStep={() => setStep(2)}
            onSelectResident={handleSelectResident} // ✅ Pass function to Step 1
          />
        );
      case 2:
        return (
          <Step2
            formik={formik}
            goToNextStep={goToNextStep}
            selectedResident={selectedResident} // ✅ Pass resident data to Step 2
          />
        );
      case 3:
        return <Step3 formik={formik} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <form onSubmit={formik.handleSubmit}>
        {renderStep()}

        {/* Navigation Buttons */}
        <div className={styles.buttonContainer}>
          {step > 1 && (
            <button
              type="button"
              onClick={() => setStep(step - 1)}
              className={styles.navButton}
            >
              <FaArrowLeft /> Back
            </button>
          )}
          {step < 3 ? (
            <button
              type="button"
              onClick={() => setStep(step + 1)}
              className={styles.navButton}
              disabled={!formik.isValid}
            >
              Next <FaArrowRight />
            </button>
          ) : (
            <button
              type="submit"
              className={styles.submitButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className={styles.loader}></span>
              ) : (
                "Finalize"
              )}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default AddResidents;
