import "./CollapsibleSection.css";
import { ChevronLeft } from "../svgs/rentpal";

const CollapsibleSection = ({
  id,
  title,
  children,
  openCollapses,
  handleOpen,
}) => {
  const isOpen = openCollapses.includes(id);

  return (
    <div className="collapsible" key={id} id={id}>
      {/* Header */}
      <div className="collapsible-header">
        <h2 className="collapsible-title">{title}</h2>
        <span
          className={`chevron ${isOpen ? "open" : ""}`}
          onClick={() => handleOpen(id)}
        >
          <ChevronLeft />
        </span>
      </div>
      {/* Collapsible Content */}
      <div className={`collapsible-content ${isOpen ? "open" : ""}`}>
        <div className="collapsible-line">
          <div className="dot"></div>
        </div>
        <div className="content-text">{children}</div>
      </div>
    </div>
  );
};

export default CollapsibleSection;
