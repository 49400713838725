import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./loginDialog.module.css";

const LoginDialog = ({ dialogRef, isAuthenticated, onLogoutClick }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isDashboardPage = location.pathname === "/dashboard";
  return (
    <div ref={dialogRef} className={styles.dialogMainContainer}>
      <div className={styles.dialogOptions}>
        {isAuthenticated ? (
          <>
            <h3 className={styles.dialogMenu} onClick={onLogoutClick}>
              Logout
            </h3>
          </>
        ) : (
          <>
            <h3 className={styles.loginText}>Log in / Sign up</h3>
          </>
        )}
        <div className={styles.optionsBreak}></div>
        {isDashboardPage ? (
          <>
            <h3 className={styles.dialogMenu} onClick={() => navigate("/")}>
              Home
            </h3>
            <div className={styles.optionsBreak}></div>
          </>
        ) : (
          <>
            <h3
              className={styles.dialogMenu}
              onClick={() => navigate("/dashboard")}
            >
              Dashboard
            </h3>
            <div className={styles.optionsBreak}></div>
          </>
        )}
        <h3 className={styles.dialogMenu}>Rent your home</h3>
        <h3 className={styles.dialogMenu}>Help Center</h3>
      </div>
    </div>
  );
};

LoginDialog.propTypes = {
  dialogRef: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default LoginDialog;
