import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ReservationBox.module.css";
import {
  CallIcon,
  CloseIcon,
  CopyIcon,
  DropDownIconSvg,
  MobileIcon,
  WtsapIcon,
} from "../svgs/rentpal";
import CalendarComponent from "../calendarComponent/calendarComponent";

const ReservationBox = ({ propertyData }) => {
  const navigate = useNavigate();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // State for selected date
  const [isSaveVisible, setIsSaveVisible] = useState(false); // State to show Save button
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [counters, setCounters] = useState({
    wholeUnit: 1,
    floor: 1,
    rooms: 1,
    seats: 1,
  });
  const { propertyId } = useParams();

  const openCalendar = () => setIsCalendarOpen(true);

  const closeCalendar = () => {
    setIsCalendarOpen(false);
    setIsSaveVisible(false);
  };

  const handleDateSelection = (date) => {
    setSelectedDate(date);
    setIsSaveVisible(true);
  };

  const saveSelectedDate = () => {
    setIsCalendarOpen(false);
    setIsSaveVisible(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const increment = (key) => {
    setCounters((prev) => ({
      ...prev,
      [key]: prev[key] + 1,
    }));
  };

  const decrement = (key, min = 1) => {
    setCounters((prev) => ({
      ...prev,
      [key]: prev[key] > min ? prev[key] - 1 : prev[key],
    }));
  };

  const handleReserveNow = (propertyId) => {
    const storageKey = `propertyData_${propertyId}`;
    sessionStorage.setItem(storageKey, JSON.stringify(propertyData));
    navigate(`/secure-booking/${propertyId}`);
  };

  return (
    <div className={styles.reservationBoxContainer}>
      <div className={styles.reservationBoxPrice}>
        <p>PKR</p>
        <h1>45k / month</h1>
      </div>
      <p className={styles.reservationBoxSubtitle}>Total rent</p>
      <div className={styles.inputSelectContainer}>
        <div className={styles.inputGroupCheckIn} onClick={openCalendar}>
          <h1 className={styles.checkInTitle}>Check in</h1>
          <p className={styles.checkInDescription}>
            {selectedDate ||
              "Select a date to confirm your reservation with the owner"}
          </p>
        </div>
        <div>
          <div className={styles.selectGroupCheckIn} onClick={toggleDropdown}>
            <div>
              <h1 className={styles.checkInTitle}>Space</h1>
              <p className={styles.checkInDescription}>Whole unit</p>
            </div>
            <div>
              <DropDownIconSvg />
            </div>
          </div>
          {isDropdownOpen && (
            <div className={styles.reservationDropdown}>
              <div onClick={toggleDropdown} className={styles.closeButton}>
                <CloseIcon />
              </div>
              <div className={styles.reservationDropdownHeader}>
                <h1>Space</h1>
                <p>This place is available as a whole unit</p>
              </div>
              <div className={styles.dropdownContent}>
                <div className={styles.reservationDropdownItem}>
                  <div>
                    <h2>Whole Unit</h2>
                    <p>5 marla</p>
                  </div>
                  <div className={styles.counter}>
                    <button onClick={() => decrement("wholeUnit")}>-</button>
                    <h1>{counters.wholeUnit}</h1>
                    <button onClick={() => increment("wholeUnit")}>+</button>
                  </div>
                </div>
                <div className={styles.reservationDropdownItem}>
                  <div>
                    <h2>Floor</h2>
                    <p>Upto 3 floors</p>
                  </div>
                  <div className={styles.counter}>
                    <button onClick={() => decrement("floor", 1)}>-</button>
                    <h1>{counters.floor}</h1>
                    <button onClick={() => increment("floor")}>+</button>
                  </div>
                </div>
                <div className={styles.reservationDropdownItem}>
                  <div>
                    <h2>Rooms</h2>
                    <p>Max 5 rooms</p>
                  </div>
                  <div className={styles.counter}>
                    <button onClick={() => decrement("rooms", 1)}>-</button>
                    <h1>{counters.rooms}</h1>
                    <button onClick={() => increment("rooms")}>+</button>
                  </div>
                </div>
                <div className={styles.reservationDropdownItem}>
                  <div>
                    <h2>Seats</h2>
                    <p>20 seats available</p>
                  </div>
                  <div className={styles.counter}>
                    <button onClick={() => decrement("seats", 1)}>-</button>
                    <h1>{counters.seats}</h1>
                    <button onClick={() => increment("seats")}>+</button>
                  </div>
                </div>
              </div>
              <div className={styles.reservationActionsBtns}>
                <button
                  className={styles.reservationDrpdwnCloseBtn}
                  onClick={toggleDropdown}
                >
                  Close
                </button>
                <button className={styles.reservationDrpdwnSaveBtn}>
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <button
        onClick={() => handleReserveNow(propertyId)}
        className={styles.reserveButton}
      >
        Reserve Now
      </button>
      <div className={styles.reservationButtonActions}>
        <div>
          <div className={styles.callButton} onClick={toggleModal}>
            <CallIcon />
            Call
          </div>
          {isModalOpen && (
            <div className={styles.callDialogueContainer}>
              <div className={styles.callDialogueContent}>
                <div className={styles.callDialogueHeaderContent}>
                  <div className={styles.closeButton} onClick={toggleModal}>
                    <CloseIcon />
                  </div>
                </div>
                <div className={styles.hostInfo}>
                  <h2>Contact host</h2>
                  <p>Muhammad Talha</p>
                  <div className={styles.contactRow}>
                    <div className={styles.contactIconDetail}>
                      <span className={styles.mobileIcon}>
                        <MobileIcon />
                      </span>
                      <div className={styles.contactInfo}>
                        <h3>Mobile</h3>
                        <a href="tel:+923115472492">+92-3115472492</a>
                      </div>
                    </div>
                    <button className={styles.copyButton}>
                      <CopyIcon />
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <button
          className={styles.whatsappButton}
          onClick={() => {
            const message = encodeURIComponent(
              `Hello, I'm interested in this property: ${
                propertyData?.title || "Property"
              }.\n\n` +
                `📍 Location: ${propertyData?.location || "Not specified"}\n` +
                `💰 Price: PKR ${propertyData?.price || "Not specified"}\n` +
                `🔗 Check it out: ${window.location.href}`
            );
            window.open(`https://wa.me/923204771882?text=${message}`, "_blank");
          }}
        >
          <WtsapIcon />
        </button>
      </div>
      {isCalendarOpen && (
        <div className={styles.calendarDialog}>
          <div className={styles.calendarOverlay} onClick={closeCalendar}></div>
          <div className={styles.calendarContent}>
            <button className={styles.closeButton} onClick={closeCalendar}>
              <CloseIcon />
            </button>
            <CalendarComponent onDateSelect={handleDateSelection} />
            {isSaveVisible && (
              <button className={styles.saveButton} onClick={saveSelectedDate}>
                Save
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReservationBox;
