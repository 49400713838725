import React from "react";
import styles from "./ResidentsRequests.module.css";

const RequestCard = ({ request }) => {
  const {
    profileImg,
    listImg,
    name: residentName,
    idNumber,
    listing,
    duration,
  } = request;

  if (!request) {
    return <div>Error: Request data is missing.</div>;
  }

  const ReqCardBrkLine = {
    height: "1px",
    width: "100%",
    background: "rgba(171, 171, 171, 0.50)",
    strokeWidth: "1px",
  };

  return (
    <div className={styles.requestCardContainer}>
      <div className={styles.requestCardContent}>
        <div className={styles.profileInfo}>
          {profileImg && (
            <img src={profileImg} alt={`${residentName}'s profile`} />
          )}
          <h1>{request.name}</h1>
        </div>
        <div className={styles.listingInfo}>
          <div>
            <h1>ID</h1>
            <p>{idNumber}</p>
          </div>
          <div style={ReqCardBrkLine}></div>
          <div className={styles.listingInfoImgContainer}>
            <span>
              <h1>Listing</h1>
              <p>{listing}</p>
            </span>
            <img src={listImg} alt="ListImg" className={styles.listingImg} />
          </div>
          <div style={ReqCardBrkLine}></div>
          <div>
            <h1>3</h1>
            <p>{duration}</p>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <button className={styles.reserveBtn}>Reserve</button>
        <button className={styles.declineBtn}>Decline</button>
      </div>
    </div>
  );
};

export default RequestCard;
