import { Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import Home from "./pages/home/Home";
import Login from "./pages/login/login";
import Property from "./pages/separate-dashboards/managers/propertyForm/propertyForm";
import Managers from "./pages/separate-dashboards/managers/viewResidents/managers";
import ViewAllProperties from "./pages/viewAllProperties/viewAllProperties";
import PropertyUpdate from "./pages/separate-dashboards/managers/propertyUpdate/propertyUpdate";
import ViewProperty from "./pages/viewAllProperties/view-property/ViewProperty";
import SignUp from "./pages/signup/signUp";
import CompleteSignIn from "./pages/completeSignIn/completeSignIn";
import RedirectUsers from "./pages/redirectUsers/redirect";
import PrivateRoute from "./routes/privateRoutes";
import PageNotFound from "./pages/404/404";
import Layout from "./components/layout/layout";
import AdminDashboard from "./pages/separate-dashboards/managers/dahboard/adminDashboard";
import Listings from "./pages/separate-dashboards/managers/listings/listings";
import AddListings from "./pages/separate-dashboards/managers/addListings/form-custom-components/addlistings";
// import Testing from "./pages/testing/testing";
import ManageAdmins from "./pages/separate-dashboards/superAdmins/manageAdmin/manageAdmins";
import MeterReadings from "./pages/meterReadings/meterReadings";
import AppErrorBoundary from "./errorBoundaries/ErrorBoundaries";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import SuperAdminDashboard from "./pages/separate-dashboards/superAdmins/dashboard/superAdminDash";
import ViewAdmin from "./pages/separate-dashboards/superAdmins/manageAdmin/viewAdmin/viewAdmin";
import Financials from "./pages/separate-dashboards/superAdmins/manageFinancials/financials";
import AutomatedAdverts from "./pages/separate-dashboards/superAdmins/automatedAdvert/automatedAdverts";
import Announcements from "./pages/separate-dashboards/superAdmins/announcements/announcements";
import Statistics from "./pages/separate-dashboards/superAdmins/stats/statistics";
import CrashReports from "./pages/separate-dashboards/superAdmins/crashReports/crashReports";
import ResidentManagement from "./pages/separate-dashboards/managers/residentManagement/residentManagement";
import Feedback from "./pages/feedback/Feedback";
import { ResidentDash } from "./components/admin/dashboard/dashboard";
import ResidentDashboard from "./pages/separate-dashboards/residents/home/Residents";
import Guests from "./pages/separate-dashboards/residents/guests/Guests";
import Payments from "./pages/separate-dashboards/residents/payments/Payments";
import BecomeAdmin from "./pages/separate-dashboards/residents/becomeAdmin/BecomeAdmin";
import LoginSignup from "./pages/basic-login-signup/LoginSignup";
import ShowAllPhotos from "./pages/viewAllProperties/view-property/showAllPhotos/ShowAllPhotos";
import ReservationBox from "./components/reservationBox/ReservationBox";
import SecureBooking from "./pages/viewAllProperties/view-property/secureBooking/SecureBooking";
import YourResidents from "./components/yourResidents/YourResidents";
import AddResidents from "./components/yourResidents/addResidents/AddResidents";
// import NewResidents from "./components/yourResidents/newResidents/NewResidents";
import Inbox from "./components/inbox/Inbox";
import NeedHelp from "./components/needHelp/NeedHelp";
//Super-admins == top level in hierarchy that manages admins (role === 0)
// admins (subscribed customers) == 2nd level hierarchy that can buy, sell, rent, manage properties via this app (role === 1)
//registered-users (unsubscribed users with authenticated data) == 3rd level in hierarchy. Can buy, sell, rent but donot have access to premium options (role === 2)
// users/un-registered or partially registered users == last level hierarchy that can only view properties and surf our app but can't use our services and products (role === 3)
function App() {
  const {
    authenticatedSuperAdmin,
    authenticatedAdmin,
    authenticatedUser,
    authData,
    loading,
  } = useAuth();

  useEffect(() => {
    console.log({
      authenticatedSuperAdmin,
      authenticatedAdmin,
      authenticatedUser,
    });
  }, [authData]);

  // Show a loader or fallback while loading authentication data
  if (loading) {
    return <div>Loading...</div>; // Replace with your loading component if needed
  }

  if (!authenticatedSuperAdmin && !authenticatedAdmin && !authenticatedUser) {
    return (
      <Layout>
        <AppErrorBoundary>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Routes>
            <Route path="*" element={<LoginSignup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
        </AppErrorBoundary>
      </Layout>
    );
  }

  return (
    <Layout>
      <AppErrorBoundary>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          {/* General Routes (for all) */}
          <Route path="/" element={<Home />} />
          <Route path="/viewProperty/:propertyId" element={<ViewProperty />} />
          <Route path="/showAllPhotos" element={<ShowAllPhotos />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/complete-signin" element={<CompleteSignIn />} />
          <Route path="/redirect" element={<RedirectUsers />} />
          <Route path="/dashboard/inbox" element={<Inbox />} />
          <Route path="/dashboard/need-help" element={<NeedHelp />} />
          {/* <Route path="/new-resident" element={<NewResidents />} /> */}

          {/* Dynamic route */}
          {/* <Route path="/testing" element={<Testing />} /> */}
          <Route path="*" element={<PageNotFound />} />
          <Route
            path="/meter-readings"
            element={<MeterReadings espId="device1" />}
          />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/reservation" element={<ReservationBox />} />
          <Route
            path="/secure-booking/:propertyId"
            element={<SecureBooking />}
          />
          {/* User Routess */}
          {authenticatedUser && (
            <>
              <Route path="/dashboard" element={<ResidentDashboard />} />
              <Route path="/dashboard/guests" element={<Guests />} />
              <Route path="/dashboard/my-payments" element={<Payments />} />
              <Route path="/dashboard/become-admin" element={<BecomeAdmin />} />
              <Route
                path="/dashboard/smartdevices"
                element={<MeterReadings />}
              />
            </>
          )}
          {/* Admin Routes */}
          {authenticatedAdmin && (
            <>
              <Route path="/dashboard" element={<AdminDashboard />} />
              <Route path="/dashboard/listings" element={<Listings />} />
              <Route
                path="/dashboard/listings/:uuid/add-listings"
                element={<AddListings />}
              />
              <Route
                path="/dashboard/residentRequests"
                element={<ResidentManagement />}
              />
              <Route
                path="/dashboard/add-resident"
                element={<AddResidents />}
              />
              {/* <Route path="/dashboards" element={<YourResidents />} /> */}
              <Route
                path="/viewAllProperties"
                element={<ViewAllProperties />}
              />
              <Route path="/addProperty" element={<Property />} />
              {/* <Route path="/viewProperty" element={<ViewProperty />} /> */}
              <Route path="/updateProperty" element={<PropertyUpdate />} />
              <Route path="/deleteProperty" element={<PropertyUpdate />} />
              <>
                <Route
                  path="/addResident"
                  element={
                    <PrivateRoute>
                      <Managers />
                    </PrivateRoute>
                  }
                />

                <Route path="/dashboard/financials" element={<Financials />} />

                <Route
                  path="/dashboard/automatedAdverts"
                  element={<AutomatedAdverts />}
                />
                <Route
                  path="/dashboard/smartDevices"
                  element={<MeterReadings />}
                />
                <Route
                  path="/dashboard/announcements"
                  element={<Announcements />}
                />
              </>
            </>
          )}
          {/* SuperAdmin Routes */}
          {authenticatedSuperAdmin && (
            <>
              <Route path="/dashboard" element={<SuperAdminDashboard />} />
              <Route path="/manageAdmins" element={<ManageAdmins />} />
              <Route path="/financials" element={<Financials />} />
              <Route
                path="/manageAdmins/viewAdmin/:id"
                element={<ViewAdmin />}
              />
              <Route path="/automatedAdverts" element={<AutomatedAdverts />} />
              <Route path="/smartDevices" element={<MeterReadings />} />
              <Route path="/announcements" element={<Announcements />} />
              <Route path="/stats" element={<Statistics />} />
              <Route path="/crashReports" element={<CrashReports />} />
            </>
          )}
        </Routes>
      </AppErrorBoundary>
    </Layout>
  );
}

export default App;
