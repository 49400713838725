import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./UserRentalListings.module.css";
import { useAuth } from "../../context/AuthContext";
import { URL } from "../../route";

//=================>make get-data for residents in BE

const UserRentalListings = ({ rentalInfo }) => {
  const { authData } = useAuth();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await axios.get(`${URL}/api/v1/auth/admin/get-data`, {
          params: {
            type: "allListings",
            uniqueId: authData.data.uniqueId,
            filter: null,
            sortBy: "createdAt",
            page: 1,
            limit: 10,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authData.token}`,
          },
        });

        console.log(response);
        if (response.status === 200) {
          const allListings = response.data.data;

          // Filter listings where the user is a tenant
          const userListings = allListings.filter((listing) =>
            rentalInfo.some(
              (r) =>
                r.propertyId === listing._id ||
                listing.buildingFloors.some(
                  (floor) =>
                    floor._id === r.floorId &&
                    floor.roomInfo.some((room) => room._id === r.roomId)
                )
            )
          );

          setListings(userListings);
        } else {
          console.error("Error:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching listings:", error);
      } finally {
        setLoading(false);
      }
    };

    if (rentalInfo.length > 0) {
      fetchListings();
    } else {
      setLoading(false);
    }
  }, [authData, rentalInfo]);

  if (loading) return <p>Loading rental listings...</p>;
  if (listings.length === 0) return <p>No rental listings found.</p>;

  return (
    <div className={styles.rentalListingsContainer}>
      <h2>Your Rental Properties</h2>
      <ul className={styles.rentalList}>
        {listings.map((listing) => (
          <li key={listing._id} className={styles.rentalItem}>
            <strong>Property Name:</strong> {listing.propertyName || "N/A"}{" "}
            <br />
            <strong>Property ID:</strong> {listing._id} <br />
            <strong>Floors:</strong> {listing.buildingFloors.length} <br />
            <strong>Rooms:</strong>{" "}
            {listing.buildingFloors.flatMap((floor) => floor.roomInfo).length}{" "}
            <br />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserRentalListings;
