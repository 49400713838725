import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import styles from "./Listings.module.css";
import ListingImg from "../../assets/images/house_img.png";
import { PlusIcon, ThreeDotIcon } from "../svgs/rentpal";
import { URL } from "../../route";

const YourListings = () => {
  const [openMenuId, setOpenMenuId] = useState(null);
  const [filter, setFilter] = useState("All");
  const [listings, setListings] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // Track if more listings exist
  const { authData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchListings(1, true); // Initial fetch, resetting listings
  }, [authData.token, authData.data.uniqueId]);

  const fetchListings = async (pageNum, reset = false) => {
    try {
      const response = await axios.get(`${URL}/api/v1/auth/admin/get-data`, {
        params: {
          type: "allListings",
          uniqueId: authData.data.uniqueId,
          filter: null,
          sortBy: "createdAt",
          page: pageNum,
          limit: 10,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authData.token}`,
        },
      });

      if (response.status === 200) {
        const newData = response.data.data;
        setListings((prev) => (reset ? newData : [...prev, ...newData])); // Append or replace listings
        setHasMore(newData.length === 10); // If less than 10 items, no more pages left
      } else {
        console.error(response.data.message);
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching listings:", error);
      setHasMore(false);
    }
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchListings(nextPage);
  };

  const filteredListings = listings.filter(
    (listing) => filter === "All" || listing.propertyStatus?.status === filter
  );

  return (
    <div className={styles.listingsContainer}>
      <div className={styles.listingsHeaderContainer}>
        <h2>Your Listings</h2>
        <div
          className={styles.listingsAdd}
          onClick={() =>
            navigate(
              `/dashboard/listings/${authData.data.uniqueId}/add-listings`
            )
          }
        >
          <p>Add</p>
          <PlusIcon />
        </div>
      </div>
      <div className={styles.listingsHeader}>
        <span>Listing</span>
        <span>Location</span>
        <span>Rooms</span>
        <span>Floors</span>
        <span>Amenities</span>
        <span>Status</span>
        <span>Actions</span>
      </div>
      {filteredListings.map((listing) => (
        <div key={listing._id} className={styles.listingsItem}>
          <div className={styles.listingsInfo}>
            <img
              src={ListingImg}
              alt={listing.name || "Listing"}
              className={styles.listingImage}
            />
            <h1>{listing.name || "Unknown Name"}</h1>
          </div>
          <p>{listing.Address || "Unknown Location"}</p>
          <p>{listing.numberOfRooms || 0} Rooms</p>
          <p>{listing.totalFloors || 0} Floors</p>
          <p>{listing.amenities?.join(", ") || "No amenities listed"}</p>
          <span
            style={{
              color:
                listing.propertyStatus?.status === "active" ? "green" : "red",
            }}
          >
            {listing.propertyStatus?.status || "Unknown"}
          </span>
          <div>
            <span
              onClick={() =>
                setOpenMenuId(openMenuId === listing._id ? null : listing._id)
              }
              className={styles.threeDotIcon}
            >
              <ThreeDotIcon />
            </span>
          </div>
        </div>
      ))}
      {hasMore && (
        <button className={styles.loadMoreButton} onClick={loadMore}>
          Load More
        </button>
      )}
    </div>
  );
};

export default YourListings;
