import React, { useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CalendarComponent from "../../../components/calendarComponent/calendarComponent";
import { AmenitiesProperty } from "./AmenitiesProperty";
import styles from "./ViewProperty.module.css";
import {
  BathroomIcon,
  BedroomIcon,
  CheckBoxIcon,
  FillIcon,
  HeartIcon,
  MeasurementIcon,
  ShareIcon,
} from "../../../components/svgs/rentpal";
import LeafLetMap from "../../../components/leafLetMap/LeafLetMap";
import ShowAllAmenities from "./showAllAmenities/ShowAllAmenities";
import ReservationBox from "../../../components/reservationBox/ReservationBox";
import HostImage from "../../../assets/images/host-img.png";
import Reviews from "./reviewsComponent/Reviews";
import SecureBooking from "./secureBooking/SecureBooking";

const ViewProperty = () => {
  const { propertyId } = useParams(); // Get the property ID from the URL
  const location = useLocation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  // Retrieve the data from session storage or location.state
  const storageKey = `propertyData_${propertyId}`;
  const storedData = sessionStorage.getItem(storageKey);
  const propertyData =
    location.state?.propertyData || (storedData && JSON.parse(storedData));

  const BreakLineStyle = {
    width: "827px",
    height: "1px",
    background: "#ABABAB",
    margin: "5px 0",
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const staticData = {
    reviews: 4.75,
    totalReviews: 500,
    aboutProperty: "One of the most love home on RentPal",
    fvrts: "GUEST FAVORITE",
  };

  const aboutData = {
    title: "About this place",
    description1:
      "Book your stay with us and experience The Perfect and Ideal Family Room for a Comfortable & Affordable Stay in the Heart of London in one of the city's most prestigious and charming locations, Earls Court!",
    description2:
      "A HUGE & Spacious Private Quad Family Room with 3 beds in a shared flat setup with other Airbnb guests!",
    showMoreLink: "Show more",
  };

  if (!propertyData) {
    return <p>No property details available.</p>;
  }

  return (
    <div className={styles.viewPropertyMainContainer}>
      <div className={styles.viewPropertyContentContainer}>
        <div className={styles.propertyHeaderContent}>
          <h1>Property Details</h1>
          <span className={styles.viewPropertyIconsContainer}>
            <div className={styles.viewPropertyHeaderIcons}>
              <ShareIcon />
              <p>Share</p>
            </div>
            <div className={styles.viewPropertyHeaderIcons}>
              <HeartIcon />
              <p>Save</p>
            </div>
          </span>
        </div>
        {propertyData.pictures && propertyData.pictures.length > 0 ? (
          <div className={styles.viewPropertyImagesContainer}>
            <div className={styles.leftDiv}>
              {propertyData.pictures[0] && (
                <img
                  src={propertyData.pictures[0]}
                  alt="Main Property"
                  className={styles.mainImage}
                />
              )}
            </div>
            <div className={styles.rightDiv}>
              {propertyData.pictures.slice(1, 5).map((pic, index) => (
                <div key={index} className={styles.subImageContainer}>
                  <img
                    src={pic}
                    alt={`Property Picture ${index + 2}`}
                    className={styles.subImage}
                  />
                </div>
              ))}
            </div>

            {/* Show all photos button */}
            <div
              className={styles.viewPropertyImagesBtn}
              onClick={() =>
                navigate("/showAllPhotos", {
                  state: { pictures: propertyData.pictures },
                })
              }
            >
              <CheckBoxIcon />
              <h1>Show all photos</h1>
            </div>
          </div>
        ) : (
          <p>No pictures available.</p>
        )}
        <p>
          <strong>Property ID:</strong> {propertyId}
        </p>
        <div className={styles.mainContentContainer}>
          <div className={styles.mainContent}>
            <div>
              <h2>
                {propertyData?.formData?.specs?.buildingName || "Building Name"}
              </h2>
              {/* <p>
            <strong>Location:</strong>{" "}
            {propertyData?.formData?.specs?.location || "Not Specified"}
          </p>
          <p>
            <strong>Property Type:</strong> {propertyData.propertyType}
          </p>
          <p>
            <strong>Classification:</strong>{" "}
            {propertyData.propertyClassification}
          </p>
          <p>
            <strong>Unit:</strong> {propertyData.propertyUnit}
          </p>
          <p>
            <strong>Availability:</strong>{" "}
            {propertyData.isAvailable ? "Available" : "Not Available"}
          </p> */}
            </div>
            <div className={styles.viewPropertyBuildingDetailsContainer}>
              <BedroomIcon />
              <p>3 Bedrooms</p>
              <FillIcon />
              <BathroomIcon />
              <p>2 Bathrooms</p>
              <FillIcon />
              <MeasurementIcon />
              <p>5 Marla</p>
            </div>
            <div className={styles.ratingsContainer}>
              <h3>{staticData.fvrts}</h3>
              <h3>{staticData.aboutProperty}</h3>
              <h3>{staticData.reviews}</h3>
              <span
                style={{
                  background: "#DDDDDD",
                  height: "50px",
                  width: "1px",
                  margin: "0 10px",
                }}
              ></span>
              <h3>
                {staticData.totalReviews}
                <br></br>
                <a>Reviews</a>
              </h3>
            </div>
            <div style={BreakLineStyle}></div>
            <div className={styles.hostContainer}>
              <h1>Hosted By</h1>
              <div className={styles.hostDetailsContainer}>
                <div className={styles.hostImageContainer}>
                  <img src={HostImage} alt="Host" />
                </div>
                <div className={styles.hostDetails}>
                  <h1>M Talha</h1>
                  <p>Hosting Since 2014</p>
                </div>
              </div>
            </div>
            <div style={BreakLineStyle}></div>
            <div className={styles.viewPropertyAboutContainer}>
              <h1>{aboutData.title}</h1>
              <h4>{aboutData.description1}</h4>
              <div>
                <a>
                  {aboutData.showMoreLink}
                  <ArrowForwardIosIcon style={{ fontSize: "medium" }} />
                </a>
              </div>
            </div>
            <div style={BreakLineStyle}></div>
            <CalendarComponent />
            <div style={BreakLineStyle}></div>
            <div>
              <Reviews />
            </div>
            <div className={styles.amenitiesPropertyContainer}>
              <h1>What this place offers</h1>
              <div className={styles.amenitiesColumns}>
                <div className={styles.column}>
                  {AmenitiesProperty.slice(0, 4).map((amenity, index) => (
                    <div key={index} className={styles.amenitiesItem}>
                      {amenity.icon}
                      <h2>{amenity.label}</h2>
                    </div>
                  ))}
                </div>
                <div className={styles.column}>
                  {AmenitiesProperty.slice(4).map((amenity, index) => (
                    <div key={index} className={styles.amenitiesItem}>
                      {amenity.icon}
                      <h2>{amenity.label}</h2>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div
                  className={styles.showAllAmenities}
                  onClick={handleDialogOpen}
                >
                  <h1>Show all amenities</h1>
                </div>
                <ShowAllAmenities
                  open={openDialog}
                  onClose={handleDialogClose}
                  title="What this place offers"
                >
                  <div>
                    {AmenitiesProperty.map((amenity, index) => (
                      <div key={index} className={styles.amenitiesItem}>
                        {amenity.icon}
                        <h2>{amenity.label}</h2>
                      </div>
                    ))}
                  </div>
                </ShowAllAmenities>
              </div>
            </div>
            <div style={BreakLineStyle}></div>
            <h1>Where you’ll be</h1>
            <LeafLetMap />
            <div style={BreakLineStyle}></div>
            {propertyData.videos && propertyData.videos.length > 0 && (
              <div>
                <h3>Videos:</h3>
                {propertyData.videos.map((video, index) => (
                  <video
                    key={index}
                    controls
                    style={{ width: "300px", height: "200px", margin: "10px" }}
                  >
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ))}
              </div>
            )}
          </div>
          <div className={styles.reservationContainer}>
            <ReservationBox propertyData={propertyData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProperty;
