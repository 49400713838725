import React, { useState } from "react";
import styles from "./Inbox.module.css";

const conversations = [
  {
    id: 1,
    name: "John Doe",
    lastMessage: "Hey, how are you?",
    messages: ["Hey, how are you?", "I'm good, thanks!"],
  },
  {
    id: 2,
    name: "Jane Smith",
    lastMessage: "See you tomorrow!",
    messages: ["See you tomorrow!", "Sure, take care!"],
  },
  {
    id: 3,
    name: "Mike Johnson",
    lastMessage: "Let's catch up later.",
    messages: ["Let's catch up later.", "Sounds good!"],
  },
];

const Inbox = () => {
  const [selectedChat, setSelectedChat] = useState(conversations[0]);
  const [newMessage, setNewMessage] = useState("");

  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      setSelectedChat((prevChat) => ({
        ...prevChat,
        messages: [...prevChat.messages, newMessage],
      }));
      setNewMessage("");
    }
  };

  return (
    <div className={styles.inboxContainer}>
      {/* Sidebar */}
      <div className={styles.sidebar}>
        <h2 className={styles.title}>Inbox</h2>
        <ul>
          {conversations.map((chat) => (
            <li
              key={chat.id}
              className={`${styles.chatItem} ${
                selectedChat.id === chat.id ? styles.selected : ""
              }`}
              onClick={() => handleSelectChat(chat)}
            >
              <strong>{chat.name}</strong>
              <p className={styles.lastMessage}>{chat.lastMessage}</p>
            </li>
          ))}
        </ul>
      </div>

      {/* Chat Area */}
      <div className={styles.chatArea}>
        <div className={styles.chatHeader}>{selectedChat.name}</div>
        <div className={styles.chatMessages}>
          {selectedChat.messages.map((msg, index) => (
            <div key={index} className={styles.messageBubble}>
              {msg}
            </div>
          ))}
        </div>
        <div className={styles.inputContainer}>
          <input
            type="text"
            className={styles.inputBox}
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <button className={styles.sendButton} onClick={handleSendMessage}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Inbox;
