import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./Cards.module.css"; // Import the CSS module

export default function Cards({ cardsData, loading, onClick }) {
  return (
    <div className={styles.cardsContainer}>
      {loading
        ? Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className={styles.card}>
              <Skeleton className={styles.skeletonImage} />
              <Skeleton className={styles.skeletonHeader} />
            </div>
          ))
        : cardsData.map((property) => (
            <div
              key={property._id}
              className={styles.card}
              onClick={() => onClick(property)} // Pass the property object
            >
              <div className={styles.cardBody}>
                {property.pictures && property.pictures.length > 0 ? (
                  <Carousel
                    showThumbs={false}
                    showIndicators={true}
                    infiniteLoop
                    autoPlay
                    interval={3000} // Set interval to 3 seconds
                    dynamicHeight={true}
                    emulateTouch
                    swipeable
                    className={styles.carouselContainer}
                  >
                    {property.pictures.map((picture, index) => (
                      <div
                        key={index}
                        className={styles.carouselImageContainer}
                      >
                        <img
                          src={picture}
                          alt={`Property ${index + 1}`}
                          className={styles.cardImage}
                        />
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <p className={styles.noImages}>No images available</p>
                )}
              </div>
              <div
                className={styles.cardHeader}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the parent `onClick`
                  onClick(property); // Pass the property object
                }}
              >
                <h2 className={styles.buildingName}>
                  {property?.formData?.specs?.buildingName}
                  <span>,</span>
                  {property?.formData?.specs?.location || "Lahore"}
                </h2>
              </div>
            </div>
          ))}
    </div>
  );
}
