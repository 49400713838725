import React from "react";
import { FeaturesCardsData } from "./FeaturesCardsData";
import styles from "./FeaturesCards.module.css";
import { LockIcon } from "../svgs/rentpal";

const FeaturesCards = () => {
  return (
    <div className={styles.featuresCardsContainer}>
      <div className={styles.featuresCardsContent}>
        {FeaturesCardsData.map((card) => (
          <div
            key={card.id}
            className={`${styles.card} ${
              card.isLocked ? styles.lockedCard : ""
            }`}
          >
            <div className={styles.iconContainer}>{card.icon}</div>
            <div className={styles.cardContent}>
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </div>
            {card.isLocked && (
              <div className={styles.lockOverlay}>
                <LockIcon />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesCards;
