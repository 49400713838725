import React from "react";
import { Bar } from "react-chartjs-2";
import styles from "./GraphSummary.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { PrevIcon, NextIcon } from "../svgs/rentpal";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GraphSummary = () => {
  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Paid",
        data: [90000, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "black",
      },
      {
        label: "Expected",
        data: [180000, 250000, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "gray",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 13,
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `Rs ${value.toLocaleString()}`,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className={styles.businessSummaryContainer}>
      <div className={styles.businessSummaryContent}>
        <div className={styles.header}>
          <h2>Business Summary</h2>
          <a href="#more-financials" className={styles.link}>
            more in financials →
          </a>
        </div>
        <p>January - 2022</p>
        <Bar data={data} options={options} />
        <div className={styles.footer}>
          <button className={styles.arrowButton}>
            <PrevIcon /> <p>2022</p>
          </button>
          <h3>2023</h3>
          <button className={styles.arrowButton}>
            <p>2024</p> <NextIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GraphSummary;
