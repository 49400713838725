import React from "react";
import styles from "./ShowAllAmenities.module.css";
import { CloseIcon } from "../../../../components/svgs/rentpal";

const ShowAllAmenities = ({ open, onClose, title, children }) => {
  if (!open) return null;

  return (
    <div className={styles.dialogOverlay}>
      <div className={styles.dialogContent}>
        <div className={styles.dialogHeader}>
          <button className={styles.closeButton} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={styles.allAmenitiesContainer}>
          <h1>{title}</h1>
          <h3>Basics</h3>
          <div className={styles.dialogBody}>{children}</div>
        </div>
        <div className={styles.dialogFooter}></div>
      </div>
    </div>
  );
};

export default ShowAllAmenities;
