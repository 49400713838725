import React from "react";
import YourResidents from "../YourResidents";

const Step2 = ({ formik, goToNextStep, selectedResident }) => {
  return (
    <div>
      <h2>Step 2: Select a Vacant Space</h2>

      {/* Show selected resident details */}
      {selectedResident && (
        <div>
          <h3>Resident Details:</h3>
          <p>
            <strong>Name:</strong> {selectedResident.name}
          </p>
          <p>
            <strong>Phone:</strong> {selectedResident.phone}
          </p>
        </div>
      )}

      {/* Resident Selection Component */}
      <YourResidents
        onSelectVacant={(selectedSpace) => {
          formik.setFieldValue("selectedVacantSpace", selectedSpace);
          goToNextStep(); // Move to Step 3
        }}
        hideAddResidents
      />
    </div>
  );
};

export default Step2;
