import React, { useState, useEffect, useRef } from "react";
import styles from "./Navbar.module.css";
import LoginDialog from "../loginDialog/loginDialog";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {
  HamburgerSVG,
  RentPalSVG,
  RLogoSVG,
  UserIconSVG,
} from "../svgs/rentpal";
import AdminDash, {
  ResidentDash,
  SuperAdminDash,
} from "../admin/dashboard/dashboard";

const Navbar = () => {
  const {
    authData,
    login,
    logout,
    authenticatedSuperAdmin,
    authenticatedAdmin,
    authenticatedUser,
  } = useAuth();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const dialogRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleClickOutside = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      setIsDialogOpen(false);
    }
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    if (authenticatedSuperAdmin || authenticatedAdmin || authenticatedUser) {
      setAuthenticated(true);
    } else if (
      !authenticatedSuperAdmin &&
      !authenticatedAdmin &&
      !authenticatedUser
    ) {
      setAuthenticated(false);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const firstLetter =
    authenticatedAdmin && authData?.name
      ? authData.name.charAt(0).toUpperCase()
      : null;

  return (
    <>
      <div className={styles.navbarMainContainer}>
        <div className={styles.navbarContentContainer}>
          <div className={styles.logoMainContainer} onClick={handleHomeClick}>
            {location.pathname === "/dashboard" ? (
              <RLogoSVG />
            ) : (
              <div className={styles.navbarTitleSvgsContainer}>
                <RentPalSVG />
                <div className={styles.navbarTitle}>
                  &nbsp; &mdash; Simplifying property management
                </div>
              </div>
            )}
          </div>
          <div className={styles.navbarSvgsContainer}>
            <HamburgerSVG
              handleClick={handleClick}
              className={styles.menuIcon}
            />
            {firstLetter ? (
              <div className={styles.userIconCircle}>{firstLetter}</div>
            ) : (
              <UserIconSVG className={styles.navbarIcon2} />
            )}
            {isDialogOpen && (
              <LoginDialog
                dialogRef={dialogRef}
                isAuthenticated={isAuthenticated}
                onLogoutClick={logout}
              />
            )}
          </div>
          {location.pathname === "/dashboard" && (
            <div className={styles.dashboardContainer}>
              {authenticatedUser && <ResidentDash />}
              {authenticatedAdmin && <AdminDash />}
              {authenticatedSuperAdmin && <SuperAdminDash />}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
