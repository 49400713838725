import React, { useState } from "react";
import RequestCard from "./RequestsCards";
import { request } from "./RequestsData";
import styles from "./ResidentsRequests.module.css";
import { LeftArrow, RightArrow } from "../svgs/rentpal";

const ResidentRequests = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleCards = 3;

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? request.length - visibleCards : prevIndex - 1
    );
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === request.length - visibleCards ? 0 : prevIndex + 1
    );
  };

  const visibleRequests = request.slice(
    currentIndex,
    currentIndex + visibleCards
  );

  if (visibleRequests.length < visibleCards) {
    visibleRequests.push(
      ...request.slice(0, visibleCards - visibleRequests.length)
    );
  }

  return (
    <div className={styles.pendingRequests}>
      <h2>Pending Requests</h2>
      <div className={styles.pendingContainer}>
        <div className={styles.arrow} onClick={handleLeftClick}>
          <LeftArrow />
        </div>
        <div className={styles.carouselWindow}>
          <div
            className={styles.carouselTrack}
            style={{
              transform: `translateX(-${(100 / visibleCards) * currentIndex}%)`,
            }}
          >
            {visibleRequests.map((req) => (
              <div
                className={styles.carouselItem}
                style={{ width: `${100 / visibleCards}%` }}
                key={req.id}
              >
                <RequestCard request={req} />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.arrow} onClick={handleRightClick}>
          <RightArrow />
        </div>
      </div>
    </div>
  );
};

export default ResidentRequests;
