import {
  AutoPayoutsIcon,
  CommentIcon,
  FinancialsIcon,
  IdCardIcon,
  ListingIcon,
  ResidentsHomeIcon,
  SmartPhoneIcon,
} from "../svgs/rentpal";

export const FeaturesCardsData = [
  {
    id: 1,
    icon: <IdCardIcon />,
    title: "Personal info",
    description: "Provide personal details and how we can reach you",
    isLocked: false,
  },
  {
    id: 2,
    icon: <ListingIcon />,
    title: "Listings",
    description: "Edit your rental spaces or add another if needed",
    isLocked: false,
  },
  {
    id: 3,
    icon: <FinancialsIcon />,
    title: "Financials",
    description: "Manage your payments from tenants and edit your records",
    isLocked: false,
  },
  {
    id: 4,
    icon: <ResidentsHomeIcon />,
    title: "Residents",
    description: "Manage residents and their requests to lease your property",
    isLocked: false,
  },
  {
    id: 5,
    icon: <CommentIcon />,
    title: "Conversations",
    description: "See your existing and upcoming messages from residents",
    isLocked: false,
  },
  {
    id: 6,
    icon: <SmartPhoneIcon />,
    title: "Smart home",
    description: "Coming soon",
    isLocked: true,
  },
  {
    id: 7,
    icon: <AutoPayoutsIcon />,
    title: "Auto Payouts",
    description: "Coming soon",
    isLocked: true,
  },
];
